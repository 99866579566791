import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { languageUpdate } from '../trans/actions';
import './styles.css'; 

const SelectBox = props => { 
    const [checkbox, setCheckBox] = useState(props.checked);
    const [checkbox1, setCheckBox1] = useState(props.checked);

    useEffect(()=>{
        setCheckBox(checkbox);
    },[checkbox]);

    useEffect(()=>{
        setCheckBox1(checkbox1);
    },[checkbox1]);

    return (
        <div className="TextBoxContainer">
            <div className="leftLabelContainer" style={props.weeknumber === true ? {flex: 1} : props.activity === true ? {flex: 1.5} : props.season === true ? {flex: 1.5} : {flex: 4}}>
                <span>{props.label}</span>
            </div>

            <div className="rightLabelContainer" style={props.weeknumber === true ? {flex: 9,justifyContent:"space-around"} : props.season === true ? {flex: 8.5}  : {flex: 6}}>
                {
                    props.season === true ?
                        <label className="checkBoxContainer">
                            <input 
                                type="checkbox"
                                className="selectBox"
                                defaultChecked={checkbox}
                                onChange={()=>{  
                                    setCheckBox(!checkbox);
                                    setCheckBox1(!checkbox1);
                                    props.onTextChange(
                                        props.name,
                                        checkbox1 === true ? "false" : checkbox1 === false ? "true" : "false",
                                        props.key1,
                                        props.seasonKey
                                    )
                                }}
                                disabled={props.disabled}
                            />
                            <span className="checkboxCheckmark"></span>
                        </label>
                    :
                        <label className="checkBoxContainer">
                            <input 
                                type="checkbox"
                                className="selectBox"
                                defaultChecked={checkbox}
                                onChange={()=>{  
                                    setCheckBox(!checkbox);
                                    setCheckBox1(!checkbox1);
                                    props.onTextChange(
                                        props.name,
                                        props.name === "growArea" ?
                                            {
                                                "growAreaId": props.growAreaId,
                                                "suitability": checkbox === true ? "0" : checkbox === false ? "1" : "0"
                                            }
                                        :
                                            {
                                                "season_key": props.label,
                                                "season_suitability": checkbox1 === true ? "0" : checkbox1 === false ? "1" : "0",
                                                "sorting": props.sorting,
                                                "season_defaultPlantDate": props.defaultPlantDate
                                            }
                                    )
                                }}
                            />
                            <span className="checkboxCheckmark"></span>
                        </label>
                }
                {
                    props.weeknumber === true &&
                        <>
                            <input 
                                type="textbox"
                                className="textBoxInput"
                                placeholder="Default Plant Date"
                                defaultValue={props.week}
                                onChange={(e)=>{  
                                    props.onTextChange(
                                        props.name,
                                        {
                                            "season_key": props.label,
                                            "season_defaultPlantDate": e.target.value,
                                        }
                                    )
                                }}
                                disabled={props.disabled}
                            />
                            {
                                "editableDefaultPlantDate" in props &&
                                    <input 
                                        type="textbox"
                                        className="textBoxInput"
                                        placeholder="Default Plant Date"
                                        defaultValue={props.editableDefaultPlantDate}
                                        onChange={(e)=>{  
                                            props.onTextChange(
                                                props.name,
                                                {
                                                    "season_key": props.label,
                                                    "season_defaultPlantDate": e.target.value,
                                                }
                                            )
                                        }}
                                        disabled={false}
                                        style={{width: 100, marginLeft: 5}}
                                    /> 
                            }
                            <span style={{marginLeft: 5}}>wk no</span>
                        </>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
       lang: state.lang.language
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                languageUpdate
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectBox);