import React, { useState, useEffect } from 'react';
import Header from '../../../../common/header';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Breadcrumb from '../../../../common/breadcrumb';
import './variety.css';
import TextBox from '../../../../common/textBox';
import TextArea from '../../../../common/textArea';
import { selectVariety, deleteVarietyRequest, registerVariety } from '../../action'; 
import Spinner from '../../../../common/spinner';
import CustomButton from '../../../../common/button';
import {
    languageUpdate
} from '../../../../trans/actions';
import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';    
import { GetToOrganisation } from '../../../lib/getToOrganisation';

import Modal from 'react-modal';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root');  

const Variety = (props) => {
    const [modalIsOpen,setIsOpen] = React.useState(false);
    var languageListLength = props.language_list.length > 0 ? props.language_list.length : 1;
    var languageLength = [];

    for(let i=0; i<languageListLength; i++){
        languageLength.push(i);
    }

    const [selectedVariety, setSelectedVariety] = useState([props.selected_variety]);
    const [varietyLanguageData, setVarietyLanguageData] = useState([]);
    const [varietyData, setvarietyData] = useState([]);
    const [varietyData1, setvarietyData1] = useState([]);
    const [data, setData] = useState(props.selected_crop_id !== "" ? props.selected_crop_detail[0] : []);
    const [checkedData, setCheckedData] = useState([]);
    const [codeLocale, setCodeLocale] = useState(false);

    const getLangContent = (language) => {
        let data =  props.language_list.findIndex((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }

    const getLanguageContent = (language) => {
        let data =  props.language_list.filter((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data.length > 0){
            return data[0].locale;
        }
        else{
            return "en_EN";
        }
    }

    useEffect(()=>{
        setCheckedData(checkedData);
    },[checkedData]);

    useEffect(()=>{
        setvarietyData(props.selected_variety);
    },[props,props.selected_variety]);
 
    useEffect(()=>{
        setSelectedVariety(props.selected_variety);
    },[props,props.selected_variety]);

    useEffect(()=>{
        setData(props.selected_crop_id !== "" ? props.selected_crop_detail[0] : []);
    },[props,props.selected_crop_id]);

    useEffect(()=>{
        setVarietyLanguageData(varietyLanguageData);
    },[varietyLanguageData]);

    const onSaveButtonClick = () => {
        let data5 = varietyData1;

        let data2 = selectedVariety.length > 0 ? selectedVariety : varietyLanguageData;
        let data3 = {"cropVarietyText": data2};
        let data4 = {"crop_id": props.selected_crop_id};
        let data6 = {"organisation": props.organisation};
        let data = {...varietyData, ...data5, ...data3, ...data4, ...data6};

        if(data.codeLocale !== ""){
            props.actions.registerVariety(data);
        }
        else{
            setCodeLocale(true);
        }
    }

    useEffect(()=>{
        setCodeLocale(codeLocale);
    },[codeLocale])

    useEffect(()=>{
        if(props.selected_crop_id !== ""){
            if("id" in selectedVariety){
                let data2 = [];
                for (const [key, value] of Object.entries(varietyData)) {
                    if(key !== "variety_text"){
                        data2[key] = value;
                        if(key === "id"){data2["variety_id"] = data2["variety_id"] = value}
                        if(key === "codeLocale"){data2["codeLocale"] = value}
                        if(key === "centreLocale"){data2["centreLocale"] = value}
                        if(key === "codeOrigin"){data2["codeOrigin"] = value}
                        if(key === "centreOrigin"){data2["centreOrigin"] = value}
                        if(key === "type"){data2["type"] = value}
                        if(key === "minRendement"){data2["minRendement"] = value}
                        if(key === "maxRendement"){data2["maxRendement"] = value}
                        if(key === "minLifeCycle"){data2["minLifeCycle"] = value}
                        if(key === "maxLifeCycle"){data2["maxLifeCycle"] = value}
                        if(key === "min_seed"){data2["min_seed"] = value}
                        if(key === "max_seed"){data2["max_seed"] = value}
                    }
                }
                setvarietyData(data2);

                let data1 = [];
                if("variety_text" in varietyData){
                    varietyData.variety_text.map((crop_data_text)=>{
                        let data2 = {};
                        for (const [key, value] of Object.entries(crop_data_text)) {
                            data2[key] = value;
                        }
                        data1.push(data2);
                        return ()=>{};
                    })
                    setVarietyLanguageData(data1);
                }
            }
        }
    },[selectedVariety, props.selected_crop_id]);

    const getLanguage = () => {
        let data0 = Object.values(varietyLanguageData).findIndex((temp_data)=>{return temp_data.locale === languageContent});
        return data0;
    }

    const getLanguage1 = () => {
        let data0 = Object.values(varietyLanguageData).findIndex((temp_data)=>{return temp_data.locale === 'en_EN'});
        return data0;
    }

    const getVarietylanguage = (id) => {
        if(data.variety.length > 0){
            let data0 = data.variety[id].variety_text.findIndex((temp_data)=>{return temp_data.locale === languageContent});
            return data0;
        }
    }

    useEffect(()=>{
        setSelectedVariety([]);
        setVarietyLanguageData([]);
    },[props.variety_register_loading,props.variety_delete_loading])

    var langContent = getLangContent(props.lang);
    var languageContent = getLanguageContent(props.lang);

    const editVarietyData = (variety) => {
        let index = variety.variety_text.findIndex((tmp)=>{ return tmp.locale.includes(props.lang)});

        document.getElementById('name').value = langContent in variety.variety_text ? variety.variety_text[index].name : "";
        document.getElementById('description').value = langContent in variety.variety_text ? variety.variety_text[index].description : "";
        document.getElementById('code_local').value = variety.codeLocale;
        document.getElementById('center_local').value = variety.centreLocale;
        document.getElementById('code_origin').value = variety.codeOrigin;
        document.getElementById('center_origin').value = variety.centreOrigin;
        document.getElementById('type').value = variety.type;
        document.getElementById('min_rendement').value = variety.minRendement;
        document.getElementById('max_rendement').value = variety.maxRendement;
        document.getElementById('min_life_cycle').value = variety.minLifeCycle;
        document.getElementById('max_life_cycle').value = variety.maxLifeCycle;
        document.getElementById('min_seed').value = variety.min_seed;
        document.getElementById('max_seed').value = variety.max_seed;
    }

    const textChange = (name, text) => {
        var cropVarietyText = ["name","description"];

        if(cropVarietyText.includes(name)){
            let data1 = {[name]: text};
            let data2 = getLanguage() in varietyLanguageData ? varietyLanguageData[getLanguage()] : [];

            let name1 = "name" in data2 === false && {"name": ""};
            let description = "description" in data2 === false && {"description": ""};
            let id = "id" in props.selected_variety === true ? props.selected_variety.id : "";

            let language = {"locale": languageContent};
            
            let data3 = {...id, ...name1, ...description, ...data2, ...data1, ...language};
            
            let language2 = getLanguage() === -1 ? Object.keys(varietyLanguageData).length : getLanguage();

            let data4 = {[language2]: data3};
            let data = {...varietyLanguageData, ...data4};

            setVarietyLanguageData(data);
        }
        else{
            if(name === "variety_code_locale"){
                setCodeLocale(false);
            }

            let data1 = varietyData1;
            
            let data2 = {[name]: text};
            let data3 = {"variety_id": "id" in props.selected_variety ? props.selected_variety.id : ""};
            let data4 = {...data2, ...data3};
            let data5 = {...data1, ...data4};
    
            setvarietyData1(data5);
        }
    }

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    let to_org = GetToOrganisation(props.mapping_list, props.auth); 

    const renderModal = () => {
        return (
            <Modal
                isOpen={ modalIsOpen }
                onRequestClose={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    closeModal()
                }}
                style={customStyles}
            >
                <h4>Do you really want to delete selected variety ?</h4>
                <div className="modalBox">
                    <div className="leftModal">
                        <div 
                            className="modalButton"
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal();
                                props.actions.deleteVarietyRequest(props.selected_variety.id, props.organisation);
                            }}
                        >
                            Yes
                        </div>
                    </div>

                    <div className="rightModal">
                        <div 
                            className="modalButton" 
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal()
                            }}
                        >
                            No
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    return (
        <div className="mediaContainer">
            <Header header={false} />

            <Breadcrumb />

            <div className="generalBody">
                <ToastContainer/>
                {
                    (props.variety_register_loading === true || props.variety_delete_loading === true) ?
                        <Spinner />
                    :
                        <div className="formCard" style={{flexDirection: 'column'}}>
                            {
                                languageLength.map((lang,key)=>{
                                    return(
                                        langContent === lang &&
                                            <div key={key}>
                                                {
                                                    "id" in selectedVariety === true ?
                                                        <div>
                                                            <TextBox 
                                                                label="Name" 
                                                                id="name"
                                                                name="name"
                                                                onTextChange={textChange}
                                                                value={
                                                                    getLanguage() in varietyLanguageData ? 
                                                                        varietyLanguageData[getLanguage()].name
                                                                    :
                                                                        "id" in selectedVariety ? 
                                                                            getLanguage() in selectedVariety ?
                                                                                selectedVariety.variety_text[getLanguage()].name
                                                                            :
                                                                                undefined
                                                                        :
                                                                            undefined
                                                                }
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in varietyLanguageData ? 
                                                                            varietyLanguageData[getLanguage1()].name
                                                                        :
                                                                            "id" in selectedVariety ? 
                                                                                key in selectedVariety &&
                                                                                    selectedVariety.variety_text[key].name
                                                                            :
                                                                                undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />
                                                            <TextArea
                                                                id="description"
                                                                label="Description" 
                                                                name="description"
                                                                onTextChange={textChange}
                                                                value={
                                                                    getLanguage() in varietyLanguageData ? 
                                                                        varietyLanguageData[getLanguage()].description
                                                                    :
                                                                        "id" in selectedVariety ? 
                                                                            getLanguage() in selectedVariety ?
                                                                                selectedVariety.variety_text[getLanguage()].description
                                                                            :
                                                                                undefined
                                                                        :
                                                                            undefined
                                                                }
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in varietyLanguageData ? 
                                                                            varietyLanguageData[getLanguage1()].description
                                                                        :
                                                                            "id" in selectedVariety ? 
                                                                                key in selectedVariety &&
                                                                                    selectedVariety.variety_text[key].description
                                                                            :
                                                                                undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />  
                                                        </div>
                                                    :
                                                        <div>
                                                            <TextBox
                                                                label="Name" 
                                                                name="name"
                                                                id="name"
                                                                onTextChange={textChange}
                                                                value={
                                                                    getLanguage() in varietyLanguageData ? varietyLanguageData[getLanguage()].name : undefined
                                                                }
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in varietyLanguageData ? varietyLanguageData[getLanguage1()].name : undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />  
                                                            <TextArea
                                                                label="Description" 
                                                                name="description"
                                                                id="description"
                                                                onTextChange={textChange}
                                                                value={getLanguage() in varietyLanguageData ? varietyLanguageData[getLanguage()].description : undefined}
                                                                value1={
                                                                    languageContent !== 'en_EN' ?
                                                                        getLanguage1() in varietyLanguageData ? varietyLanguageData[getLanguage1()].description : undefined
                                                                    :
                                                                        undefined
                                                                }
                                                            />  
                                                        </div>
                                                }
                                            </div>
                                    )
                                })                             
                            }
                            
                            <TextBox 
                                id="code_local"
                                label="Code Local" 
                                name="codeLocale"
                                onTextChange={textChange}
                                value={
                                    "id" in selectedVariety ? 
                                        selectedVariety.codeLocale
                                    :
                                        undefined
                                }
                                disabled={"id" in selectedVariety === true ? true : false}
                                error={codeLocale === true ? "You must enter Code Locale" : undefined}
                            />
                            <TextBox 
                                id="center_local"
                                label="Center Local" 
                                name="centreLocale"
                                onTextChange={textChange}
                                value={
                                    "id" in selectedVariety ? 
                                        selectedVariety.centreLocale
                                    :
                                        undefined
                                }
                            />
                            <TextBox 
                                id="code_origin"
                                label="Code Origin" 
                                name="codeOrigin"
                                onTextChange={textChange}
                                value={
                                    "id" in selectedVariety ? 
                                        selectedVariety.codeOrigin
                                    :
                                        undefined
                                }
                            />
                            <TextBox 
                                id="center_origin"
                                label="Center Origin"  
                                name="centreOrigin"
                                onTextChange={textChange}
                                value={
                                    "id" in selectedVariety ? 
                                        selectedVariety.centreOrigin
                                    :
                                        undefined
                                }
                            />
                            <TextBox 
                                id="type"
                                label="Type" 
                                name="type"
                                onTextChange={textChange}
                                value={
                                    "id" in selectedVariety ? 
                                        selectedVariety.type
                                    :
                                        undefined
                                }
                            />
                            <TextBox 
                                id1="min_rendement"
                                id2="max_rendement"
                                label="Rendement" 
                                type="min_max" 
                                name1="minRendement"
                                name2="maxRendement"
                                onTextChange={textChange}
                                unit="days"
                                value1={
                                    "id" in selectedVariety ? 
                                        selectedVariety.minRendement
                                    :
                                        undefined
                                }
                                value2={
                                    "id" in selectedVariety ? 
                                        selectedVariety.maxRendement
                                    :
                                        undefined
                                }
                            />
                            <TextBox 
                                id1="min_life_cycle"
                                id2="max_life_cycle"
                                label="Life Cycle" 
                                type="min_max"
                                unit="days"
                                name1="minLifeCycle"
                                name2="maxLifeCycle"
                                onTextChange={textChange}
                                value1={
                                    "id" in selectedVariety ? 
                                        selectedVariety.minLifeCycle
                                    :
                                        undefined
                                }
                                value2={
                                    "id" in selectedVariety ? 
                                        selectedVariety.maxLifeCycle
                                    :
                                        undefined
                                }
                            />

                            <TextBox 
                                id1="min_seed"
                                id2="max_seed"
                                label="Seed" 
                                type="min_max"
                                unit="are"
                                name1="min_seed"
                                name2="max_seed"
                                onTextChange={textChange}
                                value1={
                                    "id" in selectedVariety ? 
                                        selectedVariety.minSeed
                                    :
                                        undefined
                                }
                                value2={
                                    "id" in selectedVariety ? 
                                        selectedVariety.maxSeed
                                    :
                                        undefined
                                }
                            />

                            {
                                (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                    <div style={{display: 'flex', flex: 1,alignItems: 'center',justifyContent:'center'}}>
                                        <div className="leftSelectLabelContainer1"></div>
                                        
                                        <div className="rightSelectLabelContainer1">
                                            <CustomButton
                                                name={`Save Variety`} 
                                                onSaveButtonClick={onSaveButtonClick}
                                            />
                                        </div>
                                    </div>
                                :
                                    (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                        <div style={{display: 'flex', flex: 1,alignItems: 'center',justifyContent:'center'}}>
                                            <div className="leftSelectLabelContainer1"></div>
                                            
                                            <div className="rightSelectLabelContainer1">
                                                <CustomButton
                                                    name={`Save Variety`} 
                                                    onSaveButtonClick={onSaveButtonClick}
                                                />
                                            </div>
                                        </div>
                            }

                            <table className="tableContainer" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>Variety Name</th>
                                        <th>Code Local</th>
                                        <th>Center Local</th>
                                        <th>Center Origin</th>
                                        <th>Type</th>
                                        <th>Rendement Min/Max</th>
                                        <th>Life Cycle Min/Max</th>
                                        <th>Seed Min/Max</th>
                                        {
                                            (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                                <>
                                                    <th>
                                                        <span>Edit</span>
                                                    </th>
                                                    <th>
                                                        <span>Delete</span> 
                                                    </th>
                                                </>
                                            :
                                                (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                                    <>
                                                        <th>
                                                            <span>Edit</span>
                                                        </th>
                                                        <th>
                                                            <span>Delete</span> 
                                                        </th>
                                                    </>
                                        }
                                    </tr>
                                </thead> 
                                {
                                    "variety" in data === true &&
                                        data.variety.map((variety,key)=>{
                                            return (
                                                <tbody key={key} className={"id" in selectedVariety ? selectedVariety.id === variety.id ? "selectedRow" : "" : ""}> 
                                                    <tr>
                                                        <td>
                                                            {   
                                                                getVarietylanguage(key) in variety.variety_text ?
                                                                    variety.variety_text[getVarietylanguage(key)].name
                                                                :
                                                                    undefined
                                                            }
                                                        </td>
                                                        <td>{variety.codeLocale}</td>
                                                        <td>{variety.centreLocale}</td>
                                                        <td>{variety.centreOrigin}</td>
                                                        <td>{variety.type}</td>
                                                        <td>{variety.minRendement} - {variety.maxRendement}</td>
                                                        <td>{variety.minLifeCycle} - {variety.maxLifeCycle}</td>
                                                        <td>{variety.min_seed} - {variety.max_seed}</td>
                                                        {
                                                            (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                                                <>
                                                                    <td style={{justifyContent: "center",alignItems: "center"}}>
                                                                        <img 
                                                                            src={require('../../../../images/edit.png')} 
                                                                            alt="edit" 
                                                                            style={{width: 20,height: 20,cursor: "pointer"}}
                                                                            onClick={()=>{
                                                                                document.getElementById('name').value = "";
                                                                                document.getElementById('description').value = "";
                                                                                document.getElementById('code_local').value = "";
                                                                                document.getElementById('center_local').value = "";
                                                                                document.getElementById('code_origin').value = "";
                                                                                document.getElementById('center_origin').value = "";
                                                                                document.getElementById('type').value = "";
                                                                                document.getElementById('min_rendement').value = "";
                                                                                document.getElementById('max_rendement').value = "";
                                                                                document.getElementById('min_life_cycle').value = "";
                                                                                document.getElementById('max_life_cycle').value = "";
                                                                                document.getElementById('min_seed').value = "";
                                                                                document.getElementById('max_seed').value = "";
                                                                                setSelectedVariety([]);
                                                                                setVarietyLanguageData([]);
                                                                                editVarietyData(variety);
                                                                                props.actions.selectVariety(variety);
                                                                                window.scrollTo(0, 0);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td style={{justifyContent: "center",alignItems: "center"}}>
                                                                        <img 
                                                                            src={require('../../../../images/delete.png')} 
                                                                            alt="delete" 
                                                                            style={{width: 20,height: 20,cursor: "pointer"}}
                                                                            onClick={()=>{
                                                                                props.actions.selectVariety(variety);
                                                                                openModal();
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </>
                                                            :
                                                                (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                                                    <>
                                                                        <td style={{justifyContent: "center",alignItems: "center"}}>
                                                                            <img 
                                                                                src={require('../../../../images/edit.png')} 
                                                                                alt="edit" 
                                                                                style={{width: 20,height: 20,cursor: "pointer"}}
                                                                                onClick={()=>{
                                                                                    document.getElementById('name').value = "";
                                                                                    document.getElementById('description').value = "";
                                                                                    document.getElementById('code_local').value = "";
                                                                                    document.getElementById('center_local').value = "";
                                                                                    document.getElementById('code_origin').value = "";
                                                                                    document.getElementById('center_origin').value = "";
                                                                                    document.getElementById('type').value = "";
                                                                                    document.getElementById('min_rendement').value = "";
                                                                                    document.getElementById('max_rendement').value = "";
                                                                                    document.getElementById('min_life_cycle').value = "";
                                                                                    document.getElementById('max_life_cycle').value = "";
                                                                                    document.getElementById('min_seed').value = "";
                                                                                    document.getElementById('max_seed').value = "";
                                                                                    setSelectedVariety([]);
                                                                                    setVarietyLanguageData([]);
                                                                                    editVarietyData(variety);
                                                                                    props.actions.selectVariety(variety);
                                                                                    window.scrollTo(0, 0);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td style={{justifyContent: "center",alignItems: "center"}}>
                                                                            <img 
                                                                                src={require('../../../../images/delete.png')} 
                                                                                alt="delete" 
                                                                                style={{width: 20,height: 20,cursor: "pointer"}}
                                                                                onClick={()=>{
                                                                                    props.actions.selectVariety(variety);
                                                                                    openModal();
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    </>     
                                                        }   
                                                    </tr>
                                                    
                                                    {
                                                        variety.variety_text.length > 0 &&
                                                            getVarietylanguage(key) in variety.variety_text ?
                                                                ![null, "NULL", ""].includes(variety.variety_text[getVarietylanguage(key)].description) &&
                                                                    <tr> 
                                                                        <td colSpan="11">
                                                                            {
                                                                                getVarietylanguage(key) in variety.variety_text ?
                                                                                    variety.variety_text[getVarietylanguage(key)].description
                                                                                :
                                                                                    undefined
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                            :
                                                                undefined
                                                    }      
                                                </tbody>
                                            )
                                        })
                                }
                            </table>
                            {
                                (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                                    renderModal()
                                :
                                    (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                        renderModal()
                            }
                        </div>
                }
            </div>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        lang: state.lang.language,
        selected_crop_detail: state.crop.selected_crop_detail,
        selected_crop_id: state.crop.selected_crop_id,
        selected_crop_detail_loading: state.crop.selected_crop_detail_loading,
        selected_variety: state.crop.selected_variety,
        variety_register_loading: state.crop.variety_register_loading,
        variety_delete_loading: state.crop.variety_delete_loading,
        user_role_type: state.auth.user_role_type,
        language_list: state.lang.language_list,
        organisation: state.auth.organization,
        mapping_list: state.organization.mapping_list,
        auth: state.auth.organization,
        organisation: state.auth.organization,
        superuser: state.auth.superuser,
        umva_id: state.auth.umva_id,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                selectVariety,
                deleteVarietyRequest,
                registerVariety,
                languageUpdate
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Variety);