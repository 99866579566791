import Api from '../../config/api';
import errorParse from '../lib/error';
import GlobalApi from '../../config/globalApi';
import axios from 'axios';

function organizationRequest(params) {
    const res = Api.get('/org/names', { params }).then((response) => {
        let data = response.data;
        return data;
    }).catch((error) => {
        return error;
    });
    return res;
}

function organizationAddRequest(params) {
    const token = localStorage.getItem("token");
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : "",
        'x-country': params.country.toLowerCase()
    }

    const res = axios.post(process.env.REACT_APP_NEPAL_AGRICOACH_BASE_URL + '/orgName', params, {
        headers: headers
    })
        .then((response) => {
            let data = response.data;
            return data;
        })
        .catch((error) => {
            return errorParse(error);
        });

    return res;
    // const res = Api.post('/orgName',params).then((response)=>{
    //     let data = response.data;
    //     return data;
    // }).catch((error)=>{
    //     return errorParse(error);
    // });
    // return res;
}

function deleteOrganisationRequest(params) {
    const res = Api.get('/deleteOrg', { params }).then((response) => {
        let data = response.data;
        return data;
    }).catch((error) => {
        return errorParse(error);
    });
    return res;
}

function getparticularorganisationDetail(params) {
    const res = Api.get('/getlocale', { params }).then((response) => {
        let data = response.data;
        return data;
    }).catch((error) => {
        return errorParse(error);
    });
    return res;
}

function countriesListRequest() {
    const res = Api.get('/to_organisationList').then((response) => {
        let data = response.data;
        return data;
    }).catch((error) => {
        return errorParse(error);
    });
    return res;
}

function organisationListRequest() {
    const res = GlobalApi.get('/common/bank/all').then((response) => {
        let data = response.data;
        return data;
    }).catch((error) => {
        return errorParse(error);
    });
    return res;
}

function mappingListRequest() {
    const res = Api.get('/mappingslist').then((response) => {
        let data = response.data;
        return data;
    }).catch((error) => {
        return errorParse(error);
    });
    return res;
}

function countryRequest() {
    const res = Api.get('/country/names').then((response) => {
        let data = response.data;
        return data;
    }).catch((error) => {
        return errorParse(error);
    });
    return res;
}

export const OrganizationService = {
    organizationRequest,
    organizationAddRequest,
    deleteOrganisationRequest,
    getparticularorganisationDetail,
    countriesListRequest,
    organisationListRequest,
    mappingListRequest,
    countryRequest
}

