import SeasonActionTypes from './actionTypes';

import { fork, take, call, put, delay } from "redux-saga/effects";

import { SeasonService } from "./service";

import history from '../../config/history';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function* seasonRequest() {
    while(true){
        const { data } = yield take(SeasonActionTypes.SEASON_LIST_REQUEST);

        var season_list = yield call(SeasonService.seasonRequest, data);

        if(season_list){
            season_list = season_list.sort((a,b)=>{return a.Band > b.Band ? 1: -1});

            yield put({
                type: SeasonActionTypes.SEASON_LIST_SUCCESS,
                season_list: season_list
            })
        }
        else{
            yield put({
                type: SeasonActionTypes.SEASON_LIST_FAILURE,
            })
        }
    }
}

export function* seasonAddRequest() {
    while(true){
        const { data } = yield take(SeasonActionTypes.ADD_SEASON_REQUEST);

        var seasons = yield call(SeasonService.addSeasonRequest, data);

        if(seasons === "Season key is added."){ 
            yield put({
                type: SeasonActionTypes.ADD_SEASON_SUCCESS
            })
            let data1 = {};
         
            data1.organisation = JSON.parse(localStorage.getItem('user')).organization;

            var season_list = yield call(SeasonService.seasonRequest, data1);
       
            if(season_list){
                toast.success("Season has been added/updated successfully", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });

                yield put({
                    type: SeasonActionTypes.SEASON_LIST_SUCCESS,
                    season_list: season_list
                })
            }
            else{
                yield put({
                    type: SeasonActionTypes.SEASON_LIST_FAILURE,
                })
            }
            yield delay(2000);

            history.push(`/home`);
            history.push(`/season`);
        }
        else{
            if("error" in seasons){
                toast.error(seasons.error.message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }
            yield put({
                type: SeasonActionTypes.ADD_SEASON_FAILURE
            })
        }
    }
}

export function* seasonDeleteRequest() {
    while(true){
        const { data } = yield take(SeasonActionTypes.DELETE_SEASON_REQUEST);

        var seasons = yield call(SeasonService.deleteSeasonRequest,data);
        
        if(seasons === "Selected rows has been deleted."){
            yield put({
                type: SeasonActionTypes.DELETE_SEASON_SUCCESS
            })

            let data1 = {};
         
            data1.organisation = JSON.parse(localStorage.getItem('user')).organization;

            var season_list = yield call(SeasonService.seasonRequest, data1);
       
            if(season_list){
                season_list = season_list.sort((a,b)=>{return a.Band > b.Band ? 1 : -1});

                toast.success("Season has been deleted successfully", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });

                yield put({
                    type: SeasonActionTypes.SEASON_LIST_SUCCESS,
                    season_list: season_list
                })
            }
            else{
                yield put({
                    type: SeasonActionTypes.SEASON_LIST_FAILURE,
                })
            }

            yield delay(1000);

            history.push(`/home`);
            history.push(`/season`);
        }
        else{
            yield put({
                type: SeasonActionTypes.DELETE_SEASON_FAILURE
            })
        }
    }
}

export default function* SeasonSaga(){
    yield fork(seasonRequest);
    yield fork(seasonAddRequest);;
    yield fork(seasonDeleteRequest);
}

