import React, {useEffect, useState} from 'react';
import Header from '../../../common/header';
import Breadcrumb from '../../../common/breadcrumb';
import Spinner from '../../../common/spinner';
import '../../crop/view/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextBox from '../../../common/textBox';
import { 
    seasonListRequest, 
    selectSeason, 
    removeSelectedSeason,
    seasonDeleteRequest, 
    addSeasonRequest 
} from '../action';
import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import FileType from '../../../common/fileType';
import { GetToOrganisation } from "../../lib/getToOrganisation";

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root');

const Index = (props) => {
    var image_type = ["image/png", "image/jpeg"];
    const [file, setFile] = useState(''); 
    const [fileSizeError, setFileSizeError] = useState(false);
    const [fileTypeError, setFileTypeError] = useState(false);

    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [seasonData, setSeasonData] = useState({});
    const [seasonList, setSeasonList] = useState(props.season_list);
    const [selectedSeason, setSelectedSeason ] = useState([]);
    const [selectedSeasonid, setSelectedSeasonId] = useState('');

    useEffect(()=>{
        setFile("");
    },[]);

    useEffect(()=>{
        let index = props.organization_list.findIndex((tmp)=>{return tmp.organisation === props.organization});

        let params = {
            organisation: props.organization,
            crop_id: ""
        }
        if(index !== -1){
            const {latitude, longitude} = props.organization_list[index];
            if(latitude == null && longitude == null){
                params = {...params, ...{latitude: -3.3731, longitude: 29.9189}};
            }
            else{
                params = {...params, ...{latitude: latitude, longitude: longitude}};
            }
        }
        else{
            params = {...params, ...{latitude: -3.3731, longitude: 29.9189}};
        }

        props.actions.seasonListRequest(params);
    },[props.actions]);

    useEffect(()=>{
        setSeasonList(props.season_list);
        props.actions.removeSelectedSeason();
    },[props.season_list]);

    useEffect(()=>{
        setSelectedSeason(selectedSeason);
    },[selectedSeason]);

    useEffect(()=>{
        setFile(file)
    },[file]);

    useEffect(()=>{
        setFileSizeError(fileSizeError);
        setFileTypeError(fileTypeError);
    },[fileSizeError, fileTypeError]);

    const getCountry = () => {
        let data = props.organization_list.filter((tmp)=>{return tmp.organisation === props.organization});

        if(data.length > 0){
            return data[0].country;
        }
        else{
            return "BI";
        }
    }

    const textChange = (key,text) => {
        setSeasonData(Object.assign(
            seasonData, 
            {[key]: text}, 
            {"org": props.organization}, 
            {"season_id": selectedSeasonid !== "" ? selectedSeasonid : ""
        }));
    }

    const onSaveButtonClick = () => {
        if(fileTypeError === false){
            setFileTypeError(false);
            if(fileSizeError === false){
                setFileSizeError(false);  

                let data = {...seasonData, ...{"season_img": file}};
                data = {...data, ...{"country": getCountry()}};
                
                props.actions.addSeasonRequest(data);
            }
            else{
                setFileSizeError(true); 
            }
        }
        else{
            setFileTypeError(true);
        }
    }

    const onEditButtonPressed = (season) => {
        document.getElementById('season_key').value = season.key;
        document.getElementById('band').value = season.Band;
        document.getElementById('start_week').value = season.startWeek;
        document.getElementById('end_week').value = season.endWeek;
        setSeasonData([]);
        setSeasonData(Object.assign(
            seasonData, {
                "season_id":season.id,
                "season_key":season.key,
                "startWeek": season.startWeek,
                "endWeek": season.endWeek,
                "org": props.organization,
                "band": season.Band
            }
        ))
        
        props.actions.selectSeason(season);
    }

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    const handleChange = (event) => {
        if(image_type.includes(event.target.files[0].type) === true){
            setFileTypeError(false);
            if(event.target.files[0].size <= 100000){
                setFileSizeError(false);
                toDataURL(URL.createObjectURL(event.target.files[0]),
                    function (dataUrl) {
                        let temp_data_url = dataUrl.split(",");
                        setFile(temp_data_url[1]);
                    }
                )
            }
            else{
                setFileSizeError(true); 
                setFile("");
            }
        }
        else{
            setFileTypeError(true);
            setFile("");
        }
    }

    const toDataURL = (src, callback, outputFormat) => {
        let image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = function () {
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');
          let dataURL;
          canvas.height = this.naturalHeight;
          canvas.width = this.naturalWidth;
          ctx.drawImage(this, 0, 0);
          dataURL = canvas.toDataURL(outputFormat);
          callback(dataURL);
        };
        image.src = src;
        if (image.complete || image.complete === undefined) {
          image.src = "data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
          image.src = src;
        }
    }

    let to_org = GetToOrganisation(props.mapping_list, props.auth); 

    const renderForm = () => {
        return (
            <form>
                <TextBox 
                    id="season_key"
                    label="Season Name" 
                    name="season_key"
                    onTextChange={textChange}
                    value={
                        "id" in selectedSeason ? 
                            selectedSeason.key
                        :
                            undefined
                    }
                />
                <TextBox 
                    id="band"
                    label="Band" 
                    name="band"
                    value={
                        "id" in selectedSeason ? 
                            selectedSeason.Band
                        :
                            undefined
                    }
                    onTextChange={textChange}
                />

                <TextBox 
                    id="start_week"
                    label="Start Week" 
                    name="startWeek"
                    value={
                        "id" in selectedSeason ? 
                            selectedSeason.start_week
                        :
                            undefined
                    }
                    onTextChange={textChange}
                />

                <TextBox 
                    id="end_week"
                    label="End Week" 
                    name="endWeek"
                    value={
                        "id" in selectedSeason ? 
                            selectedSeason.end_week
                        :
                            undefined
                    }
                    onTextChange={textChange}
                />

                <FileType 
                    label={"Season Image"}
                    handleChange={handleChange}
                    error={fileSizeError === true ? "Maximum limit size exceeds 100 KB" : undefined}
                    error1={fileTypeError === true ? ".jpeg and .png file type are only allowed." : undefined}
                    file={
                        Object.keys(props.selected_season).length > 0 ?
                            file == "" ?
                                (props.selected_season.season_img !== null && props.selected_season.season_img !== "") &&
                                props.selected_season.season_img
                            :
                                file
                        :
                            file
                    }
                />
            </form>
        );
    }

    const renderModal = () => {
        return (
            <Modal
                isOpen={ modalIsOpen }
                onRequestClose={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    closeModal()
                }}
                style={customStyles}
            >
                <h4>Do you really want to delete season {selectedSeason.key} ?</h4>
                <div className="modalBox">
                    <div className="leftModal">
                        <div 
                            className="modalButton"
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                props.actions.seasonDeleteRequest({"season_id": selectedSeason.season_id, "organisation": props.organization})
                                closeModal()
                            }}
                        >
                            Yes
                        </div>
                    </div>

                    <div className="rightModal">
                        <div 
                            className="modalButton" 
                            onClick={(e)=>{
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal()
                            }}
                        >
                            No
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    return (
        <div className="characteristicsContainer">
            <Header displayLanguage={false} />

            <Breadcrumb 
                save={
                    (props.user_role_type === "Superuser" && props.organisation === to_org) ? 
                        true 
                    : 
                        (props.user_role_type === "Superuser" && props.umva_id === props.superuser) ?
                            true
                        :
                            false
                    } 
                onSaveButtonClick={onSaveButtonClick} 
            />

            <div className="generalBody"> 
                <ToastContainer/>
                {
                    props.selected_season === true || props.season_add_loading === true || props.season_delete_loading === true ?
                        <Spinner />
                    :
                        <div className="formCard" style={{flexDirection: 'column'}}>
                            <div className="leftFormCard">
                                {
                                    (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                                        renderForm()
                                    :
                                        (props.user_role_type === "Superuser" && props.umva_id === props.superuser) &&
                                            renderForm()
                                }
                            </div>

                            <div className="rightFormCard"></div>

                            <table style={{width: "50%"}} className="tableContainer" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Season Name</th>
                                        <th>Band</th>
                                        <th>Start Week</th>
                                        <th>End Week</th>
                                        {
                                            (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                                                <>
                                                    <th>
                                                        <span>Edit</span> 
                                                    </th>
                                                    <th>
                                                        <span>Delete</span> 
                                                    </th>
                                                </>
                                            :
                                                (props.user_role_type === "Superuser" && props.umva_id === props.superuser) &&
                                                    <>
                                                        <th>
                                                            <span>Edit</span> 
                                                        </th>
                                                        <th>
                                                            <span>Delete</span> 
                                                        </th>
                                                    </>
                                        }
                                    </tr>
                                </thead> 
                                {
                                    seasonList.length > 0 &&
                                        seasonList.map((season,key)=>{
                                            return (
                                                <tbody key={key} className={selectedSeasonid !== "" ? season.id === selectedSeasonid ? "selectedRow" : "" : ""}>
                                                    <tr>
                                                        <td style={{display: "flex", justifyContent: "center"}}>
                                                        {
                                                            "season_img" in season === true &&
                                                                (season.season_img !== null && season.season_img !== "") ?
                                                                    <img 
                                                                        src={`data:image/png;base64, ${season.season_img}`}
                                                                        alt="season"
                                                                        className="headerImage"
                                                                    />
                                                                :
                                                                    <div className="headerImage" style={{alignItems:'center'}}></div>
                                                        }
                                                        </td>
                                                        <td>{season.key}</td>
                                                        <td>{season.Band}</td>
                                                        <td>{season.startWeek}</td>
                                                        <td>{season.endWeek}</td>
                                                        {
                                                            (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                                                                <>
                                                                    <td style={{justifyContent: "center",alignItems: "center"}}>
                                                                        <img 
                                                                            src={require('../../../images/edit.png')} 
                                                                            alt="edit" 
                                                                            style={{width: 20,height: 20,cursor: "pointer"}}
                                                                            onClick={()=>{
                                                                                document.getElementById('season_key').value = "";
                                                                                document.getElementById('band').value = "";
                                                                                document.getElementById('start_week').value = "";
                                                                                document.getElementById('end_week').value = "";
                                                                                onEditButtonPressed(season);
                                                                                setSelectedSeasonId(season.id);
                                                                                setFile(season.season_img);
                                                                                window.scrollTo(0,0)
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td style={{justifyContent: "center",alignItems: "center"}}>
                                                                        <img 
                                                                            src={require('../../../images/delete.png')} 
                                                                            alt="delete" 
                                                                            style={{width: 20,height: 20,cursor: "pointer"}}
                                                                            onClick={(e)=>{
                                                                                e.stopPropagation()
                                                                                e.preventDefault()
                                                                                openModal();
                                                                                setSelectedSeason({"key":season.key, "season_id": season.id})
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </>
                                                            :
                                                                (props.user_role_type === "Superuser" && props.umva_id === props.superuser) &&
                                                                    <>
                                                                        <td style={{justifyContent: "center",alignItems: "center"}}>
                                                                            <img 
                                                                                src={require('../../../images/edit.png')} 
                                                                                alt="edit" 
                                                                                style={{width: 20,height: 20,cursor: "pointer"}}
                                                                                onClick={()=>{
                                                                                    document.getElementById('season_key').value = "";
                                                                                    document.getElementById('band').value = "";
                                                                                    onEditButtonPressed(season);
                                                                                    setSelectedSeasonId(season.id);
                                                                                    setFile(season.season_img);
                                                                                    window.scrollTo(0,0)
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td style={{justifyContent: "center",alignItems: "center"}}>
                                                                            <img 
                                                                                src={require('../../../images/delete.png')} 
                                                                                alt="delete" 
                                                                                style={{width: 20,height: 20,cursor: "pointer"}}
                                                                                onClick={(e)=>{
                                                                                    e.stopPropagation()
                                                                                    e.preventDefault()
                                                                                    openModal();
                                                                                    setSelectedSeason({"key":season.key, "season_id": season.id})
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    </>
                                                        }
                                                    </tr>
                                                </tbody>
                                            )
                                        })
                                }
                            </table>
                        </div>
                    }
            </div>
            {
                (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                    renderModal()
                :   
                    (props.user_role_type === "Superuser" && props.umva_id === props.superuser) &&
                        renderModal()
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        season_list: state.season.season_list,
        season_list_loading: state.season.season_list_loading,
        selected_season: state.season.selected_season,
        lang: state.lang.language,
        language: state.lang.language === 'en' ? 0 : state.lang.language === 'fr' ? 1 : 2,
        language1: state.lang.language === 'en' ? 'en_EN' : state.lang.language === 'fr' ? 'fr_FR' : 'rn_KI',
        organization: state.auth.organization,
        lang: state.lang.language,
        season_list_loading: state.season.season_list_loading,
        season_add_loading: state.season.season_add_loading,
        season_delete_loading: state.season.season_delete_loading,
        user_role_type: state.auth.user_role_type,
        organization_list: state.organization.organization_list,
        mapping_list: state.organization.mapping_list,
        auth: state.auth.organization,
        organisation: state.auth.organization,
        superuser: state.auth.superuser,
        umva_id: state.auth.umva_id,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                seasonListRequest, 
                selectSeason,
                removeSelectedSeason,
                seasonDeleteRequest,
                addSeasonRequest
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Index);