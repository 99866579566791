import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './activity.css';
import TextBox from '../../../../common/textBox';
import TextArea from '../../../../common/textArea';
import CustomButton from '../../../../common/button';
import './activity.css';
import '../../../index.scss';
import {
    activityList,
    specificCropActivityListRequest,
    specificCropActivityListRequest1,
    activityMediaRegister,
    cropSpecificActivityRequest,
    allMediaListRequest,
    clearSelectedActivityMedia,
    allGenericListRequest,
    genericCropActivityListRequest,
    selectedGenericActivity,
    removeSelectedGenericActivity
} from './action';

import {
    getCategory
} from '../../../activity/action';

import {
    genericActivities
} from '../cropCalendars/action';

import {
    languageUpdate
} from '../../../../trans/actions';
import history from '../../../../config/history';

import 'react-toastify/dist/ReactToastify.css';
import { GetToOrganisation } from '../../../lib/getToOrganisation';

const ActivityMedia = (props) => {
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [genericActivities, setGenericActivities] = useState(props.genericActivities);
    const [activityError, setActivityError] = useState(false);
    const [addBeforeGenericList, setAddBeforeGenericList] = useState([]);
    const [agricoachSeasons, setAgricoachSeasons] = useState([]);
    const [seasonError, setSeasonError] = useState(false);
    const [mediaTitleError, setMediaTitleError] = useState(false);

    useEffect(()=>{
        let temp_locale = [];
        props.language_list.map((tmp, key)=>{
            temp_locale.push(tmp.locale);

            if(key === Object.values(props.language_list).length - 1){
                setLanguages(temp_locale);
            }
        })
    },[]);

    useEffect(()=>{
        if(props.activityCrop !== undefined){
            let temp = [...props.activityCrop];
            if(Object.values(temp).length > 0){
                let temp_seasons = [];
                Object.values(temp).map((tmp)=>{
                    if(!temp_seasons.includes(tmp.season_key)){
                        temp_seasons.push(tmp.season_key);
                    }
                })
                setAgricoachSeasons(temp_seasons);
            }
        }
    },[props.activityCrop]);

    useEffect(()=>{
        setAddBeforeGenericList(addBeforeGenericList);
    },[addBeforeGenericList]);

    useEffect(()=>{
        if(props.selected_crop_id === 0){
            let data = Object.values(props.activityMediaList).filter((tempData)=>{
                return tempData.id === props.selectedActivityMediaId;
            });
            if(data.length > 0){
                setAddBeforeGenericList(data[0].media);
            }
        }
    },[props.selectedActivityMediaId]);

    var languageListLength = props.language_list.length > 0 ? props.language_list.length : 1;
    var languageLength = [];

    for(let i=0; i<languageListLength; i++){
        languageLength.push(i);
    }

    const [data, setData] = useState([]);

    const [mediaText, setMediaText] = useState([]);
    const [mediaType, setMediaType] = useState("");
    const [activityMediaList, setActivitymediaList] = useState(props.activity_media_list);

    const getLangContent = (language) => {
        let data =  props.language_list.findIndex((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }

    const getLanguageContent = (language) => {
        let data =  props.language_list.filter((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data.length > 0){
            return data[0].locale;
        }
        else{
            return "en_EN";
        }
    }

    useEffect(()=>{
        setActivitymediaList(activityMediaList)
    },[props.activity_media_list]);

    useEffect(()=>{
        props.actions.getCategory();
    },[]);

    const onSaveButtonClick = () => {
        let main_seasons = [];
        let remove_season_keys = [];

        if(props.activityCrop !== undefined){
            if(Object.values(props.activityCrop).length > 0){
                Object.values(props.activityCrop).map((tmp)=>{
                    if(!main_seasons.includes(tmp.season_key)){
                        main_seasons.push(tmp.season_key);
                    }
                })
            }
        }

        if(main_seasons.length > 0){
            remove_season_keys = main_seasons.filter((tmp)=>{
                return !agricoachSeasons.includes(tmp);
            });
        }

        setCodeError(false);
        if(Object.keys(mediaText).length > 0){
            setMediaTitleError(false);

            let added_language = languages;

            let media_type = [];
            
            for(let i = 0; i<added_language.length ; i++){
                let tempData = [];
                tempData.title = i in mediaText ? mediaText[i].title : "";
                tempData.description = i in mediaText ? mediaText[i].description : "";
                tempData.link = i in mediaText ? mediaText[i].link : "";
                //tempData.mediaType = mediaType === "" ? mediaText[langContent].mediaType !== "" ? mediaText[langContent].mediaType : "" : mediaType;
                tempData.mediaType = i in mediaText ? mediaText[i].mediaType : "";
                tempData.locale = i in mediaText ? mediaText[i].locale : added_language[0];
                tempData.mediatext_id = i in mediaText ? mediaText[i].mediatext_id : "";
                
                if(i in mediaText){
                    media_type.push(tempData);
                }
            }
            let data0 = {["mediaText"]: media_type};

            let data3 = "activity_id" in data === false ? {"activity_id": parseInt(props.selected_activity_media)} : {"activity_id":parseInt(props.selected_activity_media)};
            let data4 = "sorting" in data === false ? {"sorting": getLastIndex()} : {"sorting": data.sorting};
            let data1 = {"crop_id": props.selected_crop_id};
            let organization = {"organisation": props.organization};
            let season_keys = {"season_keys": agricoachSeasons};
            let code_data = {"code": code};
            let data2 = {...data, ...data3, ...data4, ...data1, ...data0, ...organization, ...season_keys, ...code_data, ...{remove_season_keys: remove_season_keys}};

            let media_text = [];
            Object.values(media_type).map((tempMedia)=>{
                media_text.push({...tempMedia});
            });

            if(["NULL", "", null].includes(media_text[0].mediaType) === true){
                data2.mediaText = JSON.stringify(media_text);
            
                if(data2.activity_id === "" || !data2.activity_id){
                    setActivityError(true);
                }
                else{
                    setActivityError(false);

                    setSeasonError(false);
                    props.actions.activityMediaRegister(data2);
                }
            }   
            else{
                if(code === ""){
                    setCodeError(true);
                }
                else{
                    data2.mediaText = JSON.stringify(media_text);
            
                    if(data2.activity_id === "" || !data2.activity_id){
                        setActivityError(true);
                    }
                    else{
                        setActivityError(false);

                        setSeasonError(false);
                        props.actions.activityMediaRegister(data2);
                    }
                }
            }
        }
        else{
            setMediaTitleError(true);
            if(agricoachSeasons.length === 0){
                setSeasonError(true);
            }
            else{
                setSeasonError(false);
            }
        }
    }

    useEffect(()=>{
        setMediaText(mediaText);
    },[mediaText]);

    useEffect(()=>{
        setMediaType(mediaType);
    },[mediaType]);

    useEffect(()=>{
        setData(data);
    },[data]);

    useEffect(()=>{
        if(props.selected_crop_id === 0){
            props.actions.genericActivities();

            props.actions.allGenericListRequest(
                props.selected_crop_id
            )
        }
        else{
            props.actions.cropSpecificActivityRequest();

            props.actions.allMediaListRequest(
                props.selected_crop_id,
                props.organisation
            );
        }
    },[]);

    useEffect(()=>{
        setActivityError(activityError);
    },[activityError]);

    useEffect(()=>{
        setMediaText(mediaText);
    },[props.activityId]);

    useEffect(()=>{
        setData(data);
    },[data,props.activityId]);

    const checkLanguageExists = (offset) => {
        return Object.values(offset).findIndex((tmp)=>{return tmp.locale.includes(props.lang)});
    }

    const textChange1 = (name, text) => {
        if(["description", "title", "link", "mediaType"].includes(name) === true){
            let check = checkLanguageExists(mediaText);
            let data0 = check !== -1 ? mediaText[check] : [];

            if(name === "mediaType"){
                setMediaType(text)
            }
 
            let title = "title" in data0 ? {"title": data0.title} : {"title": ""};
            let link = "link" in data0 ? {"link": data0.link} : {"link": ""};
            let description = "description" in data0 ? {"description": data0.description} : {"description": ""};
            let mediaType = "mediaType" in data0 ? {"mediaType": data0.mediaType} : {"mediaType": "NULL"};
            let mediatext_id = "mediatext_id" in data0 ? {"mediatext_id": data0.mediatext_id} : {"mediatext_id": ""};

            let data1 = {...data0, ...description, ...title, ...link, ...mediaType, ...mediatext_id};

            data1[name] = text;

            let language = {"locale": languageContent};

            let data2 = {...data1, ...language};

            var data3 = {[check === -1 ? Object.values(Array(mediaText)[0]).length : check]: data2};

            var data4 = {...mediaText, ...data3};

            setMediaText(data4);    
        }
        else if(name === "code"){
            setCode(text);  
            setCodeError(false);
        }
        else{
            if(name === "activity_id"){
                setData([]);
                setMediaText([]);
                setActivityError(false);

                if(text !== "all"){
                    props.activityId("all")

                    if(props.selected_crop_id !== 0){
                        props.actions.specificCropActivityListRequest({
                            "activity_id" : text,
                            "crop_id": props.selected_crop_id,
                            "organisation": props.organisation
                        })
                    }
                    else{
                        props.actions.selectedGenericActivity(props.selected_generic_activity);
                        props.actions.genericCropActivityListRequest({
                            "activity_id": text,
                            "crop_id": props.selected_crop_id,
                            "organisation": props.organisation
                        })
                    }
                    setData([]);
                    setMediaText([]);
                }
                else{
                    history.push('/home');
                    history.push('/crop/activity_media');
                    history.push('/crop/crop_calendars');
                    history.goBack();
                    props.actions.clearSelectedActivityMedia();  
                    setActivitymediaList([]);
                    props.activityId("")
                    setData([]);      
                    setMediaText([]);
                }
            }
            else if(name === "seasons"){
                setSeasonError(false);
                if(!agricoachSeasons.includes(text)){
                    let tmp_seasons = agricoachSeasons;
                    tmp_seasons.push(text);
                    setAgricoachSeasons(tmp_seasons);
                }
                else{
                    let tmp_seasons = agricoachSeasons;
                    let index = agricoachSeasons.findIndex((tmp)=>{return tmp === text});
                    if(index !== -1 ){
                        tmp_seasons.splice(index, 1);
                        setAgricoachSeasons(tmp_seasons);
                    }
                }
            }
            let data0 = [];
            data0[name] = text;

            let data1 = {...data, ...data0};
            setData(data1);            
        }
    }

    const getLanguage = () => {
        if(Object.keys(mediaText).length > 0){
            let index = Object.values(mediaText).findIndex((tempData)=>{
                return tempData.locale === languageContent;
            })
            return index;
        }
        else{
            return -1;
        }
    }

    const getLastIndex  = () =>{
        if(props.specific_crop_activity_list1 !== undefined){
            if(props.specific_crop_activity_list1.length > 0){
                if(Object.values(props.specific_crop_activity_list1[0].media).length > 0){
                    let sort = props.specific_crop_activity_list1[0].media.sort((a, b) => (a.sorting > b.sorting) ? 1 : -1)
                    if(sort.length > 0){
                        return sort[sort.length - 1].sorting + 1;
                    }
                    else{
                        return 0;
                    }
                }
                else{
                    return 0;
                }
            }
        }

        if(Object.keys(activityMediaList).length >  0){
            if(Object.values(activityMediaList[0].media).length > 0){
                let sort = activityMediaList[0].media.sort((a, b) => (a.sorting > b.sorting) ? 1 : -1)
                if(sort.length > 0){
                    return sort[sort.length - 1].sorting + 1;
                }
                else{
                    return 0;
                }
            }
            else{
                return 0;
            }
        }
        else{
            return 0;
        }
    }

    useEffect(()=>{
        setMediaText([]);
    },[props.editButton])

    useEffect(()=>{
        if("id" in props.editSelectedMedia){
            let data1 = {
                "crop_id": props.selected_crop_id,
                "sorting": props.editSelectedMedia.sorting,
                "activity_id": props.editSelectedMedia.activity_id,
                "media_id": props.editSelectedMedia.id
            }
            let data2 = [];

            if("media_text" in props.editSelectedMedia){
                props.editSelectedMedia.media_text.map((tempData)=>{
                    let data3 = [];
                    data3.locale = tempData.locale;
                    data3.title = tempData.title;
                    data3.description = tempData.description;
                    data3.link = tempData.link;
                    data3.mediaType = tempData.mediaType;
                    data3.mediatext_id = tempData.mediatext_id;
                    data2.push(data3);
                });
            }
            setCode(props.editSelectedMedia.code);
            setData(data1);
            setMediaText(data2);
        }
    },[props.editSelectedMedia]);

    const selectCategory = (category_id) => {
        setMediaText([]);
        if(category_id !== "all"){
            props.actions.selectedGenericActivity(category_id);

            if(Object.keys(props.genericActivities).length > 0){
                let data = props.genericActivities.filter((tempData)=>{
                    return tempData.category == category_id
                })
                setGenericActivities(data);
            }
        }
        else{
            history.push('/home');
            history.push('/crop/activity_media');
            history.push('/crop/crop_calendars');
            history.goBack();
            props.actions.selectedGenericActivity("");
            setGenericActivities(props.genericActivities);
        }
    }

    useEffect(()=>{
        props.actions.selectedGenericActivity(props.selected_generic_activity);

        if(Object.keys(props.genericActivities).length > 0){
            let data = props.genericActivities.filter((tempData)=>{
                return tempData.category == props.selected_generic_activity
            })
            setGenericActivities(data);
        }
    },[props.genericActivities,props.selected_generic_activity]);

    var langContent = getLangContent(props.lang);
    var languageContent = getLanguageContent(props.lang);

    const getLanguageAvtivity = (activityData) => {
        let data = activityData.findIndex((tempData)=>{
            return tempData.locale === languageContent;
        });
        return data;
    }

    const getLanguageIndex = () => {
        let data = Object.values(mediaText).findIndex((temp_data)=>{
            return temp_data.locale === "en_EN"
        });
        return data;
    }

    const getSelectedLanguageData = (offset) => {
        let index = offset.findIndex((tmp)=>{return tmp.locale.includes(props.lang)});

        if(index === -1){
            let enIndex = offset.findIndex((tmp)=>{return tmp.locale === "en_EN"});
            return enIndex;
        }
        else{
            return index;
        }
    }

    const getMediaContextData = () => {
        let index = Object.values(mediaText).findIndex((tmp)=>{return tmp.locale.includes(props.lang)});

        return index;
    }

    const checkSeasonExists = (seasonOffset) => {
        let index = agricoachSeasons.findIndex((tmp)=>{return tmp === seasonOffset});

        if(index === -1)
            return false;
        else
            return true;
    }

    const getLangIndex = () => {
        let data0 = Object.values(mediaText).findIndex((temp_data)=>{return temp_data.locale === languageContent});
        return data0;
    }  

    const getLocaleData = (localeData) => {
        let data = localeData.findIndex((tempData)=>{return tempData.locale.includes(props.lang)});
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }
    
    let to_org = GetToOrganisation(props.mapping_list, props.auth); 

    const getStatusFromActivity = (category_id) => {
        if(Object.values(props.genericActivities).length > 0){
            let index = props.genericActivities.findIndex((tmp)=>{return tmp.category == category_id});

            if(index !== -1){
                return props.genericActivities[index].status;
            }
            else{
                return false;
            }
        }
        else{
            return false;
        }
    }

    const renderData = () => {
        return (
            <div>
                {
                    props.selected_crop_id === 0 &&
                        <div className="TextBoxContainer">

                            <div className="leftSelectLabelContainer1">
                                <span>Category</span>
                            </div>

                            <div className="rightSelectLabelContainer1" style={{display: "flex",flexDirection: 'column'}}>
                                <select 
                                    className="selectInput"
                                    name="category"
                                    value={props.selected_generic_activity}
                                    onChange={(e)=>{selectCategory(e.target.value)}}
                                >
                                    <option>Choose</option>
                                    {
                                        props.generic_category.length > 0 && 
                                            props.generic_category.map((tempData,key)=>{
                                                return (
                                                    getStatusFromActivity(tempData.category) &&
                                                        <option key={key} value={tempData.id}>{
                                                            tempData.activity_text.length > 0 ?
                                                                tempData.activity_text[getLanguageAvtivity(tempData.activity_text)] !== undefined &&
                                                                    tempData.activity_text[getLanguageAvtivity(tempData.activity_text)].locale === languageContent ? 
                                                                        tempData.activity_text[getLanguageAvtivity(tempData.activity_text)].name
                                                                    :
                                                                        undefined
                                                            :
                                                                undefined
                                                        }</option>
                                                )
                                            })
                                    }
                                    <option value="all">All</option>
                                </select>
                            </div>
                        </div>
                }

                <div className="TextBoxContainer">
                    <div className="leftSelectLabelContainer1">
                        <span>Activity</span>  
                    </div>

                    <div className="rightSelectLabelContainer1">
                        <div style={{display: "flex",flexDirection: 'column'}}>
                            {
                                activityError === true &&
                                    <span className="errorText">{'This field is required'}</span>
                            }
                            <select 
                                className="selectInput"
                                name="activity_id"
                                value={props.selected_activity_media !== "" ? props.selected_activity_media : props.selectedActivityMediaId}
                                onChange={(e)=>{
                                    textChange1("activity_id",e.target.value); 
                                }}
                            >
                                {
                                    props.selected_crop_id !== 0 ?
                                        <option value="all">All</option>
                                    :
                                        <option>Choose</option>
                                }
                                {
                                    props.selected_crop_id !== 0 ? 
                                        Object.keys(props.crop_specific_activity).length > 0 &&
                                            Object.values(props.crop_specific_activity).map((tempData,key)=>{
                                                return (
                                                    <option key={key} value={tempData.id}>{
                                                        tempData.activity_text !== undefined ?
                                                            tempData.activity_text.length > 0 ?
                                                                tempData.activity_text[getSelectedLanguageData(tempData.activity_text)].name
                                                            :
                                                                undefined
                                                        :
                                                            undefined
                                                    }</option>
                                                )
                                                
                                            })
                                    :
                                        genericActivities.length > 0 && 
                                            genericActivities.map((tempData,key)=>{
                                                return (
                                                    <option key={key} value={tempData.id}>{
                                                        tempData.activity_text.length > 0 ?
                                                            tempData.activity_text[getSelectedLanguageData(tempData.activity_text)].name
                                                        :
                                                            undefined
                                                    }</option>
                                                )
                                            })
                                }
                            </select>
                        </div>
                    </div>
                </div>

                {
                    languageLength.map((lang, key)=>{
                        return (
                            langContent === lang &&
                                <div key={key}>
                                    {   
                                        <TextBox    
                                            label="Media Title" 
                                            name="title" 
                                            id="title"
                                            onTextChange={textChange1} 
                                            value={
                                                getLangIndex() != -1 ?
                                                    getLangIndex() in mediaText ? 
                                                        mediaText[getLangIndex()].title 
                                                    : undefined
                                                :
                                                    undefined
                                            }
                                            value1={
                                                props.lang !== "en" &&
                                                    getLanguageIndex() !== -1 ?
                                                        Object.values(mediaText).length > 0 ?
                                                            langContent !== 0 ?
                                                                mediaText[getLanguageIndex()].title
                                                            :
                                                                mediaText[getLanguageIndex()].title
                                                        :
                                                            undefined
                                                    :
                                                        undefined
                                            }
                                            error={mediaTitleError === true ? "Media Title is required" : ""}
                                        />
                                    }
                                </div>
                            )
                        })
                    }

                    {
                        languageLength.map((lang, key)=>{
                            return (
                                langContent === lang &&
                                    <div key={key}>
                                        {
                                            <TextArea 
                                                label="Description" 
                                                name="description" 
                                                id="description"
                                                onTextChange={textChange1} 
                                                value={
                                                    getLangIndex() != -1 ?
                                                        getLangIndex() in mediaText ? 
                                                            mediaText[getLangIndex()].description 
                                                        : undefined
                                                    :
                                                        undefined
                                                }
                                                value1={
                                                    props.lang !== "en" &&
                                                        getLanguageIndex() !== -1 ?
                                                            Object.values(mediaText).length > 0 ?
                                                                langContent !== 0 ?
                                                                    mediaText[getLanguageIndex()].description
                                                                :
                                                                    mediaText[getLanguageIndex()].description
                                                            :
                                                                undefined
                                                        :
                                                            undefined
                                                }
                                            />
                                        }
                                    </div> 
                            )
                        })
                    }

                    {
                        props.selected_crop_id === 0 &&
                            <div className="TextBoxContainer" >
                                <div className="leftSelectLabelContainer1">
                                    <span>Seasons</span>
                                </div>

                                <div className="rightSelectLabelContainer1">
                                    <div style={{display: "flex",flexDirection: 'column'}}>
                                        {
                                            seasonError === true &&
                                                <span className="errorText">{"One of the season needs to be selected."}</span>
                                        }
                                        <div style={{display: "flex",flexDirection: 'row', flexWrap: 'wrap'}}>
                                            {
                                                Object.values(props.seasons).length &&
                                                    Object.values(props.seasons).map((season, key)=>{
                                                        return(
                                                            season.key.includes('w') === false &&
                                                                <label className="container" key={key}>
                                                                    <input 
                                                                        id={"season"+key}
                                                                        type="checkbox" 
                                                                        name={season.key} 
                                                                        value={season.key} 
                                                                        onChange={(e)=>textChange1('seasons',e.target.name)} 
                                                                        checked={checkSeasonExists(season.key)}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                    {season.key}
                                                                </label>
                                                        )
                                                    })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }

                    <TextBox 
                        label="Code" 
                        name="code"
                        id="code"
                        value={code}
                        error={codeError === true && "Code is required."}
                        onTextChange={textChange1}
                    />

                    {
                        languageLength.map((lang, key)=>{
                            return (
                                langContent === lang &&
                                    <div key={key}>
                                        {
                                            getLanguage() !== -1 ?
                                                <TextBox
                                                    label="Media Link"
                                                    name="link"
                                                    value={
                                                        getLanguage() !== -1 ?  
                                                            mediaText[getLanguage()] !== undefined ?
                                                                mediaText[getLanguage()].link
                                                            :
                                                                undefined
                                                        :
                                                            undefined
                                                            
                                                    }
                                                    disabled={true}
                                                />
                                            :
                                                <TextBox
                                                    label="Media Link"
                                                    disabled={true}
                                                />
                                        }
                                    </div>
                            )
                        })
                    }

                    <div className="TextBoxContainer">
                        <div className="leftSelectLabelContainer1">
                            <span>Add before</span>
                        </div>

                        <div className="rightSelectLabelContainer1">
                            <div style={{display: "flex",flexDirection: 'column'}}>
                                <select 
                                    className="selectInput"
                                    name="sorting"
                                    value={data.sorting}
                                    onChange={(e)=>{
                                        textChange1("sorting",parseInt(e.target.value));
                                    }}
                                    defaultValue={getLastIndex()}
                                >
                                    { 
                                        props.selected_crop_id === 0 ?
                                            props.specific_crop_activity_list !== undefined &&
                                                Object.keys(props.specific_crop_activity_list).length > 0 &&
                                                    Object.values(props.specific_crop_activity_list).map((tempData1)=>{
                                                        return (
                                                            tempData1.media.map((tempData,key)=>{
                                                                return (
                                                                    <option key={key} value={tempData.sorting}>{
                                                                        Object.values(tempData.media_text).length > 0 ?
                                                                            Object.values(tempData.media_text)[getLocaleData(Object.values(tempData.media_text))] !== undefined &&
                                                                                Object.values(tempData.media_text)[getLocaleData(Object.values(tempData.media_text))].title
                                                                            :
                                                                                undefined
                                                                    }</option>
                                                                )
                                                            })
                                                        )
                                                    })
                                        :
                                            props.specific_crop_activity_list1 !== undefined &&
                                                Object.keys(props.specific_crop_activity_list1).length > 0 &&
                                                    Object.values(props.specific_crop_activity_list1).map((tempData1)=>{
                                                        return (
                                                            tempData1.media.map((tempData,key)=>{
                                                                return (
                                                                    <option key={key} value={tempData.sorting}>{
                                                                        Object.values(tempData.media_text).length > 0 ?
                                                                            Object.values(tempData.media_text)[getLocaleData(Object.values(tempData.media_text))] !== undefined &&
                                                                                Object.values(tempData.media_text)[getLocaleData(Object.values(tempData.media_text))].title
                                                                            :
                                                                                undefined
                                                                    }</option>
                                                                )
                                                            })
                                                        )
                                                    })
                                    }   
                                    <option value={getLastIndex()}>{`End`}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    {
                        languageLength.map((lang, key)=>{
                            return (
                                langContent === lang &&
                                    <div key={key}>
                                        {
                                            <div className="TextBoxContainer">
                                                <div className="leftSelectLabelContainer1">
                                                    <span>Media Type</span>
                                                </div>
        
                                                <div className="rightSelectLabelContainer1">
                                                    <div style={{display: "flex",flexDirection: 'column'}}>
                                                        <select 
                                                            className="selectInput"
                                                            value={
                                                                Object.values(mediaText).length > 0 ? 
                                                                    mediaText[getMediaContextData()] !== undefined ?
                                                                        (mediaText[getMediaContextData()].mediaType !== "" && mediaText[getMediaContextData()].mediaType !== null) ? mediaText[getMediaContextData()].mediaType : "NULL"
                                                                    :
                                                                        "NULL"
                                                                :
                                                                    "NULL"
                                                            }
                                                            value1={
                                                                mediaText.length > 0 ? 
                                                                    mediaText[getMediaContextData()] !== undefined ?
                                                                        (mediaText[getMediaContextData()].mediaType !== "" && mediaText[getMediaContextData()].mediaType !== null) ? mediaText[getMediaContextData()].mediaType : "NULL"
                                                                    :
                                                                        "NULL"
                                                                :
                                                                    "NULL"
                                                            }
                                                            name="mediaType"
                                                            onChange={(e)=>{
                                                                textChange1("mediaType",e.target.value);
                                                            }}
                                                        >
                                                            <option value={"image/jpeg"}>{`image/jpeg`}</option>
                                                            <option value={"video/mp4"}>{`video/mp4`}</option>       
                                                            <option value={"NULL"}>{`NULL`}</option>       
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                            )
                        })
                    }

                    {
                        (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                            <div className="activityContainer">
                                <div className="leftOffset"></div>
                                <div className="mainContent1">
                                    <CustomButton 
                                        name={`Save & Add More Media`} 
                                        onSaveButtonClick={onSaveButtonClick}
                                    />
                                </div>
                            </div>
                        :
                            (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                                <div className="activityContainer">
                                    <div className="leftOffset"></div>
                                    <div className="mainContent1">
                                        <CustomButton 
                                            name={`Save & Add More Media`} 
                                            onSaveButtonClick={onSaveButtonClick}
                                        />
                                    </div>
                                </div>
                    }
            </div>
        )
    }

    return (
        <>
        {
            (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
               renderData()
            :
                (props.user_role_type !== "Viewer" && props.umva_id === props.superuser)  &&
                    renderData()
        }
        </>
    )
}

const mapStateToProps = state => {
    return {
        lang: state.lang.language,
        selected_crop_id: state.crop.selected_crop_id,
        activity_list: state.activityMedia.activity_list,
        selected_activity_media: state.activityMedia.selected_activity_media,
        selected_activity_media1: state.activityMedia.selected_activity_media1,
        activity_media_list: state.activityMedia.specific_crop_activity_list,
        generic_category: state.activity.generic_category,
        crop_specific_category: state.activity.crop_specific_category,
        genericActivities: state.cropCalendar.genericActivities,
        crop_specific_activity: state.activityMedia.crop_specific_activity,
        all_media_list: state.activityMedia.all_media_list,
        specific_crop_activity_list1: state.activityMedia.specific_crop_activity_list1,
        specific_crop_activity_list: state.activityMedia.specific_crop_activity_list,
        updateActivityMedia: state.activityMedia.updateActivityMedia,
        organization: state.auth.organization,
        selected_generic_activity: state.activityMedia.selected_generic_activity,
        user_role_type: state.auth.user_role_type,
        language_list: state.lang.language_list,
        selected_crop_id: state.crop.selected_crop_id,
        organisation: state.auth.organization,
        seasons: state.season.season_list,
        mapping_list: state.organization.mapping_list,
        auth: state.auth.organization,
        organisation: state.auth.organization,
        superuser: state.auth.superuser,
        umva_id: state.auth.umva_id,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                activityList,
                specificCropActivityListRequest,
                specificCropActivityListRequest1,
                activityMediaRegister,
                getCategory,
                genericActivities,
                cropSpecificActivityRequest,
                allMediaListRequest,
                clearSelectedActivityMedia,
                languageUpdate,
                allGenericListRequest,
                genericCropActivityListRequest,
                getCategory,
                selectedGenericActivity,
                removeSelectedGenericActivity
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(ActivityMedia);
