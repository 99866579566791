import axios from 'axios';
import { decodeToken } from "react-jwt";

export const GlobalApi = () => {
    const defaultOptions = {
        baseURL: process.env.REACT_APP_TEST_IMPLEMENTOR_URL,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };

    let api = axios.create(defaultOptions);

    let client_id = process.env.REACT_APP_CLIENT_ID;
    let client_secret = process.env.REACT_APP_CLIENT_SECRET;

    api.interceptors.request.use(
        async function (config) {
            const data1 = new FormData();
            let access_token = localStorage.getItem('access_token');

            if(access_token === null || access_token == "undefined"){
                data1.append("grant_type", "client_credentials");
                data1.append("client_id", client_id);
                data1.append("client_secret", client_secret);
                data1.append("scope", "*");

                const { data } = await getOauthToken(data1);

                if (data) {
                    let token = data.access_token;
                    localStorage.setItem('access_token',token);
                    config.headers.Authorization = `Bearer ${token}`;
                }
            }
            else{
                const tokenDecode = decodeToken(access_token);
                const expTime = tokenDecode.exp;
                const currentTime = Math.round(new Date().getTime() / 1000);

                if(currentTime > expTime){
                    data1.append("grant_type", "refresh_token");
                    data1.append("client_id", client_id);
                    data1.append("client_secret", client_secret);
                    data1.append("scope", "*");
                    data1.append("refresh_token", access_token);

                    const { data } = await getOauthToken(data1);
    
                    if (data) {
                        let token = data.access_token;
                        localStorage.setItem('access_token',token);
                        config.headers.Authorization = `Bearer ${token}`;
                    }
                }
                else{
                    config.headers.Authorization = `Bearer ${access_token}`;
                }
            }
            return config;
        },
        function (err) {
            return Promise.reject(err);
        }
    );

    return api;
};

function getOauthToken(payload) {
    const res = axios({
        method: "post",
        url: process.env.REACT_APP_IMPLEMENTOR_BASE_URL + "/oauth/token",
        data: payload,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        },
    })
        .then((response) => {
            return response;
        })
        .catch((e) => {
            return e;
        });
    return res;
}

export default GlobalApi();