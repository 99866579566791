import React, { useEffect, useState } from 'react';
import Header from '../../../common/header';
import '../../crop/view/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextBox from '../../../common/textBox';
import { roleListRequest, roleSelection, assignRole, selectParticularAssignedRole } from '../action';
import Spinner from '../../../common/spinner';
import { roleRequest, deleteRoleRequest} from '../action';
import CustomButton from '../../../common/button';
import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const AddRole = (props) => {
    const [umvaId, setUmvaId] = useState();
    const [role, setRole] = useState();
    const [organizationList, setOrganizationList] = useState();
    const [errorUmvaId, setErrorUmvaId] = useState(false);
    const [errorRole, setErrorRole] = useState(false);
    const [errorOrganization, setErrorOrganization] = useState(false);
    const [roleList, setRoleList] = useState(props.role_list);
    const [selectedOrganization, setSelectedOrganization] = useState('Auxfin Burundi');
    const[userRoleId, setUserRoleId] = useState("");

    useEffect(()=>{
        props.actions.roleListRequest({
            org_id: props.org_id
        });
    },[props.org_id,props.actions]);

    useEffect(()=>{
        setRoleList(props.role_list);
    },[props.role_list]);

    const textChange = (name, text) => {
        setErrorUmvaId(false);
        setUmvaId(text);
    }

    useEffect(()=>{
        props.actions.roleRequest();
    },[props.actions]);

    const onSaveButtonClick = () => {
        if(umvaId && role && organizationList){
            setErrorUmvaId(false);
            setErrorRole(false);
            setErrorOrganization(false);

            if(role === "Role"){
                setErrorRole(true);
            }
            if(organizationList === "Organization"){
                setErrorOrganization(true);
            }

            if(userRoleId !== ""){
                props.actions.assignRole({
                    "umvaid": umvaId,
                    "role": role,
                    "org_id": organizationList,
                    "user_role_id": userRoleId
                });
            }
            else{
                props.actions.assignRole({
                    "umvaid": umvaId,
                    "role": role,
                    "org_id": organizationList
                });
            }
        }
        else{
            if(!umvaId){
                setErrorUmvaId(true);
            }
            if(!role || role === "Role"){
                setErrorRole(true);
            }
            if(!organizationList || organizationList === "Organization"){
                setErrorOrganization(true);
            }
        }
    }

    useEffect(()=>{
        setRoleList(roleList);
    },[roleList]);

    const selectedParticularAssignedRole = (data) => {
        document.getElementById('umva_id').value = data.userid;
        setUmvaId(data.userid);
        setRole(data.role_id);
        setSelectedOrganization(data.id);
        setUserRoleId(data.user_role_id);
        props.actions.selectParticularAssignedRole(data);
    }

    const changeSelectedOrganization = (organization_id) => {
        props.actions.roleListRequest({
            org_id: organization_id
        });
    }

    useEffect(()=>{
        setUmvaId(umvaId);
        setRole(role);
        setOrganizationList(organizationList);
    },[role, umvaId, organizationList]);
    
    return (
        <div className="characteristicsContainer">
            <Header header={false} displayLanguage={false} />

            <div className="generalBody">
                <ToastContainer/>
                {
                    props.loading === true || props.role_add_loading === true || props.delete_data_loading === true ?
                        <Spinner />
                    :
                    <div className="formCard" style={{flexDirection: 'column'}}>
                        {
                            props.user_role_type === "Superuser" &&
                                <div>
                                    <div>
                                        <TextBox 
                                            id={"umva_id"}
                                            label="UMVA ID" 
                                            name="umva_id"
                                            onTextChange={textChange}
                                            value={umvaId}
                                            error={errorUmvaId === true ? "UMVA ID is required." : undefined}
                                        />

                                        <div className="TextBoxContainer">
                                            <div className="leftLabelContainer">
                                                <span>Assign Role</span>
                                            </div>

                                            <div className="rightLabelContainer">
                                                <div style={{display: "flex",flexDirection: 'column'}}>
                                                    {
                                                        errorRole === true &&
                                                            <span className="errorText">Role is required.</span>
                                                    }
                                                    <select 
                                                        className="selectInput"
                                                        onChange={(e)=>{
                                                            setRole(e.target.value);
                                                            setErrorRole(false);
                                                        }}
                                                        value={role}
                                                    >
                                                        <option>Role</option>
                                                        {
                                                            props.role.length > 0 &&
                                                                props.role.map((options,key)=>{
                                                                    return (
                                                                        <option 
                                                                            key={key}
                                                                            //value={JSON.stringify(options)}
                                                                            value={options.id}
                                                                        >
                                                                            {options.role_type}
                                                                        </option>
                                                                    )
                                                                })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="TextBoxContainer">
                                            <div className="leftLabelContainer">
                                                <span>Organization</span>
                                            </div>

                                            <div className="rightLabelContainer">
                                                <div style={{display: "flex",flexDirection: 'column'}}>
                                                    {
                                                        errorOrganization === true &&
                                                            <span className="errorText">Organization is required.</span>
                                                    }
                                                    <select 
                                                        className="selectInput"
                                                        onChange={(e)=>{
                                                            setOrganizationList(e.target.value);
                                                            setErrorOrganization(false);
                                                        }}
                                                        value={organizationList}
                                                    >
                                                        <option>Organization</option>
                                                        {
                                                            props.organization_list.map((organization,key)=>{
                                                                return (
                                                                    <option 
                                                                        key={key}
                                                                        value={organization.id}
                                                                    >
                                                                        {organization.organisation}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        
                                    <div style={{display: 'flex', flex: 1,alignItems: 'center',justifyContent:'center'}}>
                                        <div className="leftSelectLabelContainer1"></div>
                                        
                                        <div className="rightSelectLabelContainer1">
                                            <CustomButton
                                                name={`Assign Role`} 
                                                onSaveButtonClick={onSaveButtonClick}
                                            />
                                        </div>
                                    </div>
                                </div>  
                        }        

                        <div className="tabHeader">
                            {
                                props.organization_list.map((tempData,key)=>{
                                    if(tempData.organisation === props.organisation){
                                        return (
                                            <div 
                                                key={key} 
                                                onClick={()=>{
                                                    changeSelectedOrganization(tempData.id)
                                                    setSelectedOrganization(tempData.organisation)
                                                }}
                                                // className={ selectedOrganization === tempData.organisation ? 
                                                //     props.organization_list.length === 1 ? "activeTab" 
                                                //     :
                                                //     key === 0 ? 'activeFirstTab' : props.organization_list.length - 1 === key ? 'activeLastTab' : 'activeMiddleTab'
                                                // :
                                                //     "tabContent"
                                                // }
                                                className="activeMiddleTab"
                                            >
                                                {tempData.organisation}
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>

                        <table style={{width: "70%"}} className="tableContainer" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>UMVA ID</th>
                                    <th>Assigned Role</th>
                                    {/* <th>Organization</th> */}
                                    {
                                        props.user_role_type === "Superuser" &&
                                            <>
                                                <th>
                                                    <span>Edit</span> 
                                                </th>
                                                <th>
                                                    <span>Delete</span> 
                                                </th>   
                                            </>
                                    }
                                </tr>
                            </thead> 
                            <tbody>
                            {
                                roleList.length > 0 &&
                                    roleList.map((role,key)=>{
                                        return (
                                            <tr key={key}>
                                                <td>{role.userid}</td>
                                                <td>{role.role_type}</td>
                                                {/* <td>{role.organisation}</td> */}
                                                {
                                                    props.user_role_type === "Superuser" &&
                                                        <>
                                                            <td style={{justifyContent: "center",alignItems: "center"}}>
                                                                <img 
                                                                    src={require('../../../images/edit.png')} 
                                                                    alt="edit" 
                                                                    style={{cursor: "pointer"}}
                                                                    onClick={()=>{
                                                                        window.scrollTo(0, 0);
                                                                        document.getElementById('umva_id').value = "";
                                                                        selectedParticularAssignedRole(role)
                                                                        //selectedOrganization()
                                                                        setErrorOrganization(false);
                                                                        setErrorRole(false)
                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{justifyContent: "center",alignItems: "center"}}>
                                                                <img 
                                                                    src={require('../../../images/delete.png')} 
                                                                    alt="edit" 
                                                                    style={{width: 20,height: 20,cursor: "pointer"}}
                                                                    onClick={()=>{
                                                                        window.scrollTo(0, 0);
                                                                        document.getElementById('umva_id').value = "";
                                                                        props.actions.deleteRoleRequest({"user_role_id": role.user_role_id})
                                                                        setErrorOrganization(false);
                                                                        setErrorRole(false)
                                                                    }}
                                                                />
                                                            </td>
                                                        </>
                                                }
                                            </tr>
                                        )
                                    })
                            }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        role_list: state.role.role_list,
        role: state.role.role,
        org_id: state.auth.uid,
        organization_list: state.organization.organization_list,
        selected_data: state.role.selected_particular_role_assigned,
        loading: state.role.loading,
        role_add_loading: state.role.role_add_loading,
        user_role_type: state.auth.user_role_type,
        delete_data_loading: state.role.delete_data_loading,
        organisation: state.auth.organization,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                roleListRequest,
                roleSelection,
                assignRole,
                selectParticularAssignedRole,
                roleRequest,
                deleteRoleRequest
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(AddRole);