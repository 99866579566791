import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from '../../../../common/header';
import { bindActionCreators } from 'redux';
import '../../../index.scss';
import SelectBox from '../../../../common/selectBox';
import TextBox from '../../../../common/textBox';
import Breadcrumb from '../../../../common/breadcrumb';
import {
    registerCropCalendarRequest
} from './action';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetToOrganisation } from '../../../lib/getToOrganisation';
import getIntersection from "../../../lib/getIntersection";
import filterIntersectionData from "../../../lib/filterIntersectionData";

const CropCalendarEdit = (props) => {
    const [season_list, setSeasonList] = useState([]);
    const [data, setData] = useState([]);
    const [cropActivity, setCropActivity] = useState([]);

    useEffect(() => {
        if (Object.values(props.season_list).length > 0) {
            let data = props.season_list.filter((tmp) => {
                return (tmp.key.includes('w') === false && tmp.key.includes('M') === false && tmp.key.includes('G') === false)
            });

            let season_data = [];

            if (data.length > 0) {
                data.map((tmp) => { season_data.push(tmp.key) });
            }

            setSeasonList(season_data);
        }
    }, [props.season_list]);


    const getLangContent = (language) => {
        let data = props.language_list.findIndex((tempData) => {
            return tempData.locale.includes(language) === true
        })
        if (data !== -1) {
            return data;
        }
        else {
            return 0;
        }
    }

    const getLanguageContent = (language) => {
        let data =  props.language_list.filter((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data.length > 0){
            return data[0].locale;
        }
        else{
            return "en_EN";
        }
    }

    useEffect(() => {
        setData(data);
    }, [data]);

    const getSelectedDataFromSeason = (season_key) => {
        let data = props.selected_crop_activity.seasons.filter((tempData) => {
            return tempData.season === season_key
        })
        return data;
    }

    const textChange = (name, text, key, season_key) => {
        if (name === "activity_id") {
            let data0 = {};
            data0[name] = text;
            let data1 = { ...data, ...data0 }
            setData(data1);
        }
        else {
            let data0 = {};
            let cropActivity1 = cropActivity;
            data0[name] = text;

            let data1 = {};
            let data3 = key in cropActivity && "season_key" in cropActivity[key] === false ? { "season_key": "" } : {};

            var seasonExists = [];
            if (cropActivity1.length > 0) {
                seasonExists = cropActivity1.filter((tempData) => {
                    return season_key === tempData.season_key;
                })
            }

            if (seasonExists.length === 0) {
                let key1 = Object.values(cropActivity1).findIndex((tempData) => { return tempData.season_key === season_key });

                let data5 = key1 === -1 ? { "season_key": season_key } : cropActivity1[key1];
                let crop_activity_id = { "cropActivity_id": getSelectedDataFromSeason(season_key)[0].cropactivity_id };
                let data8 = { ...data5, ...data0, ...crop_activity_id };
                let data6 = { [key1 === -1 ? Object.values(cropActivity1).length : key1]: data8 };

                let data7 = { ...cropActivity1, ...data6 };

                setCropActivity(data7);
            }
            else {

                let crop_activity_id = { "cropActivity_id": getSelectedDataFromSeason(season_key)[0].cropactivity_id };
                let key1 = cropActivity1.findIndex((tempData) => { return tempData.season_key === season_key });
                data1[key1] = { ...cropActivity1[key1], ...data0, ...data3, ...crop_activity_id };

                let data2 = { ...cropActivity1, ...data1 };
                setCropActivity(data2);
            }
        }
    }

    useEffect(() => {
        let data1 = [];
        let data3 = [];

        props.selected_crop_activity.seasons.map((tempData) => {
            let data2 = [];
            data2.season_key = tempData.season;
            data2.weeksAfter = tempData.weeksAfter;
            data2.duration = tempData.duration;
            data2.cropActivity_id = tempData.cropactivity_id;
            data2.season_suitability = tempData.season_suitability;
            if (tempData.duration !== "" || tempData.weeksAfter !== "") {
                data3.push(tempData.cropactivity_id);
            }
            data1.push(data2);
        })

        setCropActivity(data1);

        data3 = { "cropActivity_id": data3.join() };

        let crop_id = { "crop_id": props.selected_crop_id };
        let activity_id = { "activity_id": props.selected_crop_activity.activity_id };
        let data0 = { ...crop_id, ...activity_id };
        setData(data0);
    }, []);

    const onSaveButtonClick = () => {
        let data0 = "crop_id" in data === false && { "crop_id": props.selected_crop_id };
        let data1 = { ...data0, ...data };
        let organisation = { "organisation": props.organisation };
        let data2 = { ...data1, ...organisation };
        let crop_activity = [];

        Object.values(cropActivity).map((tempCropActivity) => {
            if ("cropActivity_id" in tempCropActivity === false) {
                if ("season_suitability" in tempCropActivity == false) {
                    let season_suitability = { "season_suitability": true };
                    tempCropActivity = { ...tempCropActivity, ...season_suitability };
                }
                else {
                    let season_suitability = { "season_suitability": true };
                    tempCropActivity = { ...tempCropActivity, ...season_suitability };
                }

                tempCropActivity = { ...tempCropActivity };
            }
            else {
                let season_suitability = { "season_suitability": tempCropActivity.season_suitability };
                tempCropActivity = { ...tempCropActivity, ...season_suitability };
                //delete tempCropActivity["cropActivity_id"];
            }
            crop_activity.push({ ...tempCropActivity })
        });
        crop_activity = [...new Map(crop_activity.map(item => [item.season_key, item])).values()];
        data2.cropActivity = JSON.stringify(crop_activity);

        props.actions.registerCropCalendarRequest(data2, "update");
    }

    const getSeasonData = (season) => {
        if (props.selected_season_list.length > 0) {
            let data = props.selected_season_list.filter((tempData) => {
                return tempData.season_key === season.charAt(0);
            })
            return data;
        }
    }

    var langContent = getLangContent(props.lang);
    var languageContent = getLanguageContent(props.lang);

    const getSeasonSuitability = (selected_crop_detail, season_key) => {
        // let data = selected_crop_detail.filter((tempData) => {
        //     return tempData.key === season_key || tempData.key === season_key + "w"
        // })

        if(props.selected_crop_detail.length > 0){
            let data = props.selected_crop_detail[0].crop_season.filter((tempData) => {
                return tempData.season_key === season_key || tempData.season_key === season_key + "w"
            })

            if (data.length > 0) {
                let returnData = [];
                data.map((tempData) => {
                    if (tempData.suitability === "0") {
                        returnData.push(false);
                    }
                    else {
                        returnData.push(true);
                    }
                })
                if (returnData.includes(true) == true) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        }
        else{
            return false;
        }
    }

    const getSeasonSuitabilityData = (season_key) => {
        if (Object.values(props.selected_crop_activity).length > 0) {
            let data = props.selected_crop_activity.seasons.filter((tempData) => {
                return tempData.season == season_key
            })
            if (data.length > 0) {
                return data[0].season_suitability;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    const getSeasonStartWeek = (season) => {
        let data = props.season_list.filter((tmp) => { return tmp.key === season });

        if (data.length > 0) {
            return data[0].startWeek;
        }
    }

    const checkSeasonExists = (season_letter) => {
        let tmp_intersection = getIntersection(props.selected_season_list, props.selected_crop_detail[0]?.crop_season, "key", "season_key");
        let intersection = filterIntersectionData(tmp_intersection);

        //let check = props.crop_season_list.findIndex((tmp) => { return tmp.season_key === season_letter });
        if(intersection.includes(season_letter))
            return 1;
        else
            return -1;
    }

    let to_org = GetToOrganisation(props.mapping_list, props.auth);

    const getSeasonDataFromSeasonList = (season) => {
        if(props.selected_crop_detail[0].crop_season.length > 0){
            var selected_season = props.selected_crop_detail[0].crop_season.filter((temp_data)=>{
                return temp_data.season_key === season;
            });
            return selected_season;
        }
    }

    const getLanguageData = (data)=> {
        let tempData = Object.values(data).findIndex((temp_data)=>{
            return temp_data.locale === languageContent
        })
        return tempData;
    }

    return (
        <div>
            <Header header={false} />

            <Breadcrumb save={
                (props.user_role_type !== "Viewer" && props.organisation === to_org) ?
                    true
                    :
                    (props.user_role_type !== "Viewer" && props.umva_id === props.superuser) ?
                        true
                        :
                        false
            }
                activity={true}
                onSaveButtonClick={onSaveButtonClick}
            />

            <div className="generalBody" style={{ paddingTop: 35 }}>
                <ToastContainer />

                <div className="formCard" style={{ flexDirection: 'column' }}>
                    <div>
                        {
                            (props.selected_crop_activity.activity[0].image !== null && props.selected_crop_activity.activity[0].image !== undefined) &&
                            <img src={`data:image/png;base64, ${atob(props.selected_crop_activity.activity[0].image)}`} width={"30"} height={"30"} style={{ marginRight: '10px', marginBottom: "-10px" }} />
                        }
                        <span>{
                            // props.selected_crop_activity.activity[0].activity_text[langContent].name
                            props.selected_crop_activity.activity[0].activity_text[getLanguageData(props.selected_crop_activity.activity[0].activity_text)].name
                        }</span>
                    </div>

                    {
                        props.selected_crop_id !== 0 ?
                            <>
                                <table
                                    style={props.selected_season_list.length > 0 ? { width: "60%", paddingTop: -20 } : { width: "10%", paddingTop: -20 }}
                                    className="tableContainer1"
                                    cellSpacing="0"
                                >
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {
                                                props.selected_season_list.length > 0 ?
                                                    props.selected_season_list.map((tempSeason, key) => {
                                                        var checkSeasonExist = checkSeasonExists(tempSeason.key);

                                                        if (checkSeasonExist !== -1) {
                                                            if (getSeasonSuitability(props.selected_season_list, tempSeason.key) === true || props.selected_crop_id === 0) {
                                                                //if (!tempSeason.key.includes("w") && !tempSeason.key.includes("i")) {
                                                                    return (
                                                                        <th key={key}>{tempSeason.key}</th>
                                                                    )
                                                                //}
                                                            }
                                                        }
                                                    })
                                                    :
                                                    undefined
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left' }}>{'Default planting date'}</td>
                                            {
                                                props.selected_season_list.length > 0 ?
                                                    props.selected_season_list.map((tempSeason, key) => {
                                                        var checkSeasonExist = checkSeasonExists(tempSeason.key);

                                                        if (checkSeasonExist !== -1) {
                                                            if (getSeasonSuitability(props.selected_season_list, tempSeason.key) === true || props.selected_crop_id === 0) {
                                                                //if (!tempSeason.key.includes("w") && !tempSeason.key.includes("i")) {
                                                                    return (
                                                                        <td style={{ width: '5%' }} key={key}>{
                                                                            ![null, undefined, ""].includes(tempSeason.defaultPlantDate) ?
                                                                                tempSeason.defaultPlantDate
                                                                            :
                                                                                getSeasonDataFromSeasonList(tempSeason.key).length > 0 ?
                                                                                    getSeasonDataFromSeasonList(tempSeason.key)[0].defaultPlantDate
                                                                                :
                                                                                    undefined
                                                                        }</td>
                                                                    )
                                                                //}
                                                            }
                                                        }
                                                    })
                                                    :
                                                    undefined
                                            }
                                        </tr>

                                        <tr>
                                            <td style={{ textAlign: 'left' }}>{'Season'}</td>
                                            {
                                                props.selected_season_list.length > 0 ?
                                                    props.selected_season_list.map((tempSeason, key) => {
                                                        var checkSeasonExist = checkSeasonExists(tempSeason.key);

                                                        if (checkSeasonExist !== -1) {
                                                            if (getSeasonSuitability(props.selected_season_list, tempSeason.key) === true || props.selected_crop_id === 0) {
                                                                //if (!tempSeason.key.includes("w") && !tempSeason.key.includes("i")) {
                                                                    return (
                                                                        <td key={key}>
                                                                            <SelectBox
                                                                                name="season_suitability"
                                                                                onTextChange={textChange}
                                                                                seasonKey={tempSeason.key}
                                                                                key1={key}
                                                                                season={true}
                                                                                checked={
                                                                                    getSeasonSuitabilityData(tempSeason.key) == undefined
                                                                                        ? getSeasonSuitabilityData(tempSeason.key)
                                                                                        : getSeasonSuitabilityData(tempSeason.key)
                                                                                }
                                                                            />
                                                                        </td>
                                                                    )
                                                                //}
                                                            }
                                                        }
                                                    })
                                                    :
                                                    undefined
                                            }
                                        </tr>

                                        <tr>
                                            <td style={{ textAlign: 'left' }}>{'Weeks after'}</td>
                                            {
                                                props.selected_season_list.length > 0 ?
                                                    props.selected_season_list.map((tempSeason, key) => {
                                                        var checkSeasonExist = checkSeasonExists(tempSeason.key);

                                                        if (checkSeasonExist !== -1) {
                                                            if (getSeasonSuitability(props.selected_season_list, tempSeason.key) === true || props.selected_crop_id === 0) {
                                                                //if (!tempSeason.key.includes("w") && !tempSeason.key.includes("i")) {
                                                                    return (
                                                                        <td key={key}>
                                                                            <TextBox
                                                                                name="weeksAfter"
                                                                                onTextChange={textChange}
                                                                                seasonKey={tempSeason.key}
                                                                                type="activity"
                                                                                key1={key}
                                                                                value={
                                                                                    getSelectedDataFromSeason(tempSeason.key).length > 0 ?
                                                                                        getSelectedDataFromSeason(tempSeason.key)[0].weeksAfter
                                                                                        :
                                                                                        undefined
                                                                                }
                                                                            />
                                                                        </td>
                                                                    )
                                                                //}
                                                            }
                                                        }
                                                    })
                                                    :
                                                    undefined
                                            }
                                        </tr>

                                        <tr>
                                            <td style={{ textAlign: 'left' }}>{'Duration'}</td>
                                            {
                                                props.selected_season_list.length > 0 ?
                                                    props.selected_season_list.map((tempSeason, key) => {
                                                        var checkSeasonExist = checkSeasonExists(tempSeason.key);

                                                        if (checkSeasonExist !== -1) {
                                                            if (getSeasonSuitability(props.selected_season_list, tempSeason.key) === true || props.selected_crop_id === 0) {
                                                                //if (!tempSeason.key.includes("w") && !tempSeason.key.includes("i")) {
                                                                    return (
                                                                        <td key={key}>
                                                                            <TextBox
                                                                                name="duration"
                                                                                onTextChange={textChange}
                                                                                seasonKey={tempSeason.key}
                                                                                type="activity"
                                                                                key1={key}
                                                                                value={
                                                                                    getSelectedDataFromSeason(tempSeason.key).length > 0 ?
                                                                                        getSelectedDataFromSeason(tempSeason.key)[0].duration
                                                                                        :
                                                                                        undefined
                                                                                }
                                                                            />
                                                                        </td>
                                                                    )
                                                                //}
                                                            }
                                                        }
                                                    })
                                                    :
                                                    undefined
                                            }
                                        </tr>
                                    </tbody>
                                </table>

                                <ul>
                                    {
                                        "media" in props.selected_crop_activity.activity ?
                                            props.selected_crop_activity.activity.media.map((tempData, key1) => {
                                                return (
                                                    "media_text" in tempData &&
                                                    tempData.media_text.filter((temp_media_data) => {
                                                        return languageContent === temp_media_data.locale
                                                    }).map((temp_data) => {
                                                        return (
                                                            <li key={key1}>{temp_data.title}</li>
                                                        )
                                                    })
                                                )
                                            })
                                            :
                                            undefined
                                    }
                                </ul>
                            </>
                            :
                            <table
                                style={props.selected_season_list.length > 0 ? { width: "60%" } : { width: "40%" }}
                                className="tableContainer1"
                                cellSpacing="0"
                            >
                                <thead>
                                    <tr>
                                        <th></th>
                                        {
                                            season_list.map((tmp) => {
                                                return (
                                                    <th>{tmp}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'left' }}>{'Default planting date'}</td>
                                        {
                                            season_list.map((tmp) => {
                                                return (
                                                    <th>{getSeasonStartWeek(tmp)}</th>
                                                )
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td style={{ textAlign: 'left' }}>{'Season'}</td>
                                        {
                                            season_list.map((tempSeason, key) => {
                                                return (
                                                    <td key={key}>
                                                        <SelectBox
                                                            name="season_key"
                                                            onTextChange={textChange}
                                                            seasonKey={tempSeason}
                                                            key1={key}
                                                            season={true}
                                                            checked={
                                                                getSelectedDataFromSeason(tempSeason).length > 0 ?
                                                                    true
                                                                    :
                                                                    false
                                                            }
                                                        />
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td style={{ textAlign: 'left' }}>{'Weeks after'}</td>
                                        {
                                            season_list.map((tempSeason, key) => {
                                                return (
                                                    <td key={key}>
                                                        <TextBox
                                                            name="weeksAfter"
                                                            onTextChange={textChange}
                                                            seasonKey={tempSeason}
                                                            type="activity"
                                                            key1={key}
                                                            value={
                                                                getSelectedDataFromSeason(tempSeason).length > 0 ?
                                                                    getSelectedDataFromSeason(tempSeason)[0].weeksAfter
                                                                    :
                                                                    undefined
                                                            }
                                                        />
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td style={{ textAlign: 'left' }}>{'Duration'}</td>
                                        {
                                            season_list.map((tempSeason, key) => {
                                                return (
                                                    <td key={key}>
                                                        <TextBox
                                                            name="duration"
                                                            onTextChange={textChange}
                                                            seasonKey={tempSeason}
                                                            type="activity"
                                                            key1={key}
                                                            value={
                                                                getSelectedDataFromSeason(tempSeason).length > 0 ?
                                                                    getSelectedDataFromSeason(tempSeason)[0].duration
                                                                    :
                                                                    undefined
                                                            }
                                                        />
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                </tbody>
                            </table>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        selected_crop_activity: state.cropCalendar.selected_crop_calendar_media,
        selected_season_list: state.season.season_list,
        selected_crop_id: state.crop.selected_crop_id,
        user_role_type: state.auth.user_role_type,
        lang: state.lang.language,
        language_list: state.lang.language_list,
        organisation: state.auth.organization,
        season_list: state.season.season_list,
        crop_season_list: 0 in state.crop.selected_crop_detail ? state.crop.selected_crop_detail[0].crop_season : state.crop.selected_crop_detail,
        mapping_list: state.organization.mapping_list,
        auth: state.auth.organization,
        organisation: state.auth.organization,
        superuser: state.auth.superuser,
        umva_id: state.auth.umva_id,
        selected_crop_detail: state.crop.selected_crop_detail,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                registerCropCalendarRequest
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(CropCalendarEdit);