const getIntersection = (array1, array2, key1, key2) => {
    let tmp_array1 = [];
    let tmp_array2 = [];

    array1.forEach(x=>tmp_array1.push(x[key1]));
    array2.forEach(x=>tmp_array2.push(x[key2]));
    
    let intersection = tmp_array2.filter(x => tmp_array1.includes(x));
    return intersection;
}

export default getIntersection;