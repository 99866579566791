import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from './spinner';
import CropContainer from '../components/crop/view/crop/cropContainer';
import Modal from 'react-modal';
import { cropSelectUnselectRequest, getBaseOrganisationCropList } from '../components/crop/action';
import SelectedCropList from './selectedCropList';
import { ToastContainer } from 'react-toastify'; 

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    },
};

Modal.setAppElement('#root');

const Step2Modal = (props) => { 
    var cropImageVisibility = useState(true);
    const [cropIntersection, setCropIntersection] = useState([]);
    const [selected_crop_list, setSelectedCropList] = useState([]);
    const [counter, setCounter] = useState(1);
    const [previousSelectedData, setPreviousSelectedData] = useState('');
    const [removedData, setRemovedData] = useState([]);

    useEffect(()=>{
        if(props.base_org_crop_list.length === 0){
            props.actions.getBaseOrganisationCropList();
        }
    },[]);

    const getLangContent = (language) => {
        let data =  props.language_list.findIndex((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }

    useEffect(()=>{
        var base_org_crop_list_data = [];
        var crop_list_data = [];
        var intersection = [];

        if(props.base_org_crop_list.length > 0){
            props.base_org_crop_list.map((tmp)=>{
                base_org_crop_list_data.push(tmp.id);
            })
        }
        if(props.crop_list.length > 0){
            props.crop_list.map((tmp)=>{
                crop_list_data.push(tmp.id);
            })
        }

        intersection = base_org_crop_list_data.filter(x => crop_list_data.includes(x));
        setCropIntersection(intersection);
    },[props.crop_list, props.base_org_crop_list]);

    const selectedCropList = (data) => {
        setPreviousSelectedData(data);

        let selectedCrop = [...props.selected_crop_list];

        if(selectedCrop.includes(data) === false){
            selectedCrop.push(data);
        }
        else{
            let index = selectedCrop.findIndex((tmp)=>{return tmp === data});
            selectedCrop.splice(index, 1);
        }

        if(removedData.includes(data) === true){
            let tmp_data = [...removedData];
            let index = tmp_data.findIndex(tmp=>{return tmp === data});
            if(index !== -1){
                tmp_data.splice(index, 1);
                setRemovedData(tmp_data);
            }
        }
        else{
            if(cropIntersection.includes(data) === true){
                let tmp_data = [...removedData];
                tmp_data.push(data);
                tmp_data = [...new Set(tmp_data)];
                setRemovedData(tmp_data);
            }
        }

        if(selectedCrop.length === 0){
            var data1 = selectedCrop;
        }
        else{
            if(counter < 2){
                let difference = selectedCrop.filter(x=>removedData.includes(x));

                if(difference.length === 0){
                    if(cropIntersection.length > selectedCrop.length){
                        var data1 =  cropIntersection.filter(x=>!selectedCrop.includes(x));
                    }
                    else
                        var data1 = selectedCrop.filter(x=>!cropIntersection.includes(x));
                }
                else{
                    var data1 = [...selectedCrop, ...cropIntersection];
                    data1 =  [...new Set(data1)];
                }
            }
            else{
                data1 = selectedCrop;
            }
        }

        props.actions.cropSelectUnselectRequest(data1);
        setCounter(counter+1);
    }

    useEffect(()=>{
        if(props.selected_crop_list.length === 0){
            if(cropIntersection.includes(previousSelectedData) === true){
                // let index = cropIntersection.findIndex((tmp)=>{return tmp === previousSelectedData});
                // let tmp = [...cropIntersection];
                // tmp.splice(index, 1); 
 
                // setSelectedCropList(tmp);
                setSelectedCropList([]);
            }
            else{
                if(removedData.length ===cropIntersection.length){
                    setSelectedCropList([]);
                }
                else{
                    if(removedData.length > 0){
                        let index = cropIntersection.findIndex((tmp)=>{return tmp === previousSelectedData});
                        let tmp = [...cropIntersection];
                        tmp.splice(index, 1);
                        setSelectedCropList(tmp);
                    }
                    else{
                        setSelectedCropList(cropIntersection);
                    }
                }
            }
        }
        else{
            if(cropIntersection.length === 0){
                setSelectedCropList(props.selected_crop_list);
            }
            else{
                if(removedData.length === 0){
                    let tmp = [...cropIntersection];
                    let data = [...tmp, ...props.selected_crop_list];

                    data = [...new Set(data)];
                    setSelectedCropList(data);
                }
                else{
                    if(removedData.length > 0){
                        //setSelectedCropList(props.selected_crop_list);
                        let remove_data_list = cropIntersection.filter(x=>removedData.includes(x));

                        if(remove_data_list.length === 0){
                            if(cropIntersection.length >= props.selected_crop_list.length){
                                var diff = cropIntersection.filter(x=>!props.selected_crop_list.includes(x));
                                setSelectedCropList(diff);
                            }
                            else{
                                var diff = props.selected_crop_list.filter(x=>!cropIntersection.includes(x));
                                setSelectedCropList(diff);
                            }
                        }
                        else{
                            setSelectedCropList(props.selected_crop_list);
                        }
                    }
                    else{
                        var diff = cropIntersection.filter(x=>!props.selected_crop_list.includes(x));
                        setSelectedCropList(diff);
                    }
                }
            }
        }
    },[cropIntersection, counter]);

    const closeModal = (e) =>{
        props.closeModal(e);
    }

    var base_org_crop = props.base_org_crop_list;
    //base_org_crop = base_org_crop.sort((a,b)=>{return (a.crop_text[0].displayName > b.crop_text[0].displayName) ? 1 : -1})

    return (
        //props.user_role_type !== "Viewer" &&
            <Modal
                isOpen={ props.isModalOpen }
                onRequestClose={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                }}
                style={customStyles, { width: '30rem', display: 'flex', flexDirection: 'row', cursor: 'pointer'}}
            >
                <SelectedCropList selected_crop_list={selected_crop_list} closeModal={closeModal} />
                
                <ToastContainer/>
                <div className="modalBox">
                {
                    ( props.crop_copy_loading === true || props.crop_register_loading === true || props.base_org_crop_list_loading === true || props.loading === true || props.selected_crop_detail_loading === true || props.publish_unpublish_loading === true ) ?
                        <Spinner />
                    :
                        <div className="cropBody">
                            {
                                props.base_org_crop_list.length > 0 &&
                                    base_org_crop.map((crop,key)=>{
                                        return (
                                            <CropContainer
                                                generic={true} 
                                                key={key} 
                                                key1={key} 
                                                data={crop} 
                                                cropImageVisibility={cropImageVisibility} 
                                                modalOpen={true}
                                                selectedCropList={selectedCropList}
                                                //selected_crop_list={props.selected_crop_list}
                                                selected_crop_list={selected_crop_list}
                                            />
                                        )
                                    })
                            }
                        </div>
                }
                </div>
            </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        user_role_type: state.auth.user_role_type,
        crop_list: state.crop.crop_list,
        loading: state.crop.loading,
        selected_crop_detail_loading: state.crop.selected_crop_detail_loading,
        publish_unpublish_loading: state.crop.publish_unpublish_loading,
        selected_crop_list: state.crop.selected_crop_list,
        language_list: state.lang.language_list,
        selected_crop_list_counter: state.crop.selected_crop_list_counter,
        base_org_crop_list_loading: state.crop.base_org_crop_list_loading,
        base_org_crop_list: state.crop.base_org_crop_list,
        crop_register_loading: state.crop.crop_register_loading,
        crop_copy_loading: state.crop.crop_copy_loading,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                cropSelectUnselectRequest,
                getBaseOrganisationCropList
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Step2Modal);