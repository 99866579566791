import React, { useEffect, useState } from 'react';
import Header from '../../../common/header';
import Breadcrumb from '../../../common/breadcrumb';
import Spinner from '../../../common/spinner';
import '../../crop/view/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextBox from '../../../common/textBox';
import TextArea from '../../../common/textArea';
import {
    growAreaListRequest,
    selectGrowArea,
    removeSelectedGrowArea,
    registerGrowAreaRequest,
    deleteGrowAreaRequest
} from '../action';
import { languageUpdate } from '../../../trans/actions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { GetToOrganisation } from "../../lib/getToOrganisation";

import FileType from '../../../common/fileType';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root');

const Index = (props) => {
    var image_type = ["image/png", "image/jpeg"];
    const [modalIsOpen, setIsOpen] = React.useState(false);
    var languageListLength = props.language_list.length > 0 ? props.language_list.length : 1;
    var languageLength = [];

    for (let i = 0; i < languageListLength; i++) {
        languageLength.push(i);
    }

    const [sorting, setSorting] = useState();
    const [growAreaList, setGrowAreaList] = useState(props.grow_area_list);
    const [selectedGrowArea, setSelectedGrowArea] = useState([]);
    const [growAreaText, setGrowAreaText] = useState([]);
    const [selectedGrowAreaId, setSelectedGrowAreaId] = useState('');
    const [edited, setEdited] = useState("");

    const [file, setFile] = useState('');
    const [fileSizeError, setFileSizeError] = useState(false);
    const [fileTypeError, setFileTypeError] = useState(false);

    const getLastSortingIndex = () => {
        if ([undefined, null, ""].includes(growAreaList) === false) {
            if (growAreaList.length > 0) {
                let sorting_list = [];

                growAreaList.map((tmp) => {
                    sorting_list.push(tmp.sorting);
                });

                return Math.max(...sorting_list)
            }
            else {
                return 0;
            }
        }
        else {
            return 0;
        }
    }

    useEffect(() => {
        setSorting({ "sorting": parseInt(getLastSortingIndex()) + 1 });
    }, []);

    useEffect(() => {
        setSelectedGrowAreaId(selectedGrowAreaId);
    }, [selectedGrowAreaId]);

    useEffect(() => {
        props.actions.growAreaListRequest({ organisation: props.organization });
    }, [props.actions]);

    useEffect(() => {
        setGrowAreaList(props.grow_area_list);
    }, [props.grow_area_list]);

    useEffect(() => {
        setSelectedGrowArea(selectedGrowArea);
    }, [selectedGrowArea]);

    useEffect(() => {
        props.actions.removeSelectedGrowArea();
    }, [props.grow_area_list]);

    useEffect(()=>{
        setEdited(edited);
    },[edited]);

    const getLangContent = (language) => {
        let data = props.language_list.findIndex((tempData) => {
            return tempData.locale.includes(language) === true
        })
        if (data !== -1) {
            return data;
        }
        else {
            return 0;
        }
    }

    const getLanguageContent = (language) => {
        let data = props.language_list.filter((tempData) => {
            return tempData.locale.includes(language) === true
        })
        if (data.length > 0) {
            return data[0].locale;
        }
        else {
            return "en_EN";
        }
    }

    const handleChange = (event) => {
        if (image_type.includes(event.target.files[0].type) === true) {
            setFileTypeError(false);
            if (event.target.files[0].size <= 100000) {
                setFileSizeError(false);
                toDataURL(URL.createObjectURL(event.target.files[0]),
                    function (dataUrl) {
                        let temp_data_url = dataUrl.split(",");
                        setFile(temp_data_url[1]);
                    }
                )
            }
            else {
                setFileSizeError(true);
                setFile("");
            }
        }
        else {
            setFileTypeError(true);
            setFile("");
        }
    }

    const toDataURL = (src, callback, outputFormat) => {
        let image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = function () {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            let dataURL;
            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            callback(dataURL);
        };
        image.src = src;
        if (image.complete || image.complete === undefined) {
            image.src = "data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
            image.src = src;
        }
    }

    const getLanguage = () => {
        let data = Object.values(growAreaText).findIndex((data) => {
            return data.locale === languageContent;
        })
        return data;
    }

    const getLanguage1 = () => {
        let data = Object.values(growAreaText).findIndex((data) => {
            return data.locale === 'en_EN';
        })
        return data;
    }

    const getLanguageIndex = (growArea) => {
        let data = Object.values(growArea).findIndex((temp_data) => {
            return temp_data.locale.includes(props.lang) === true;
        });
        return data;
    }

    const getEnglishLanguageIndex = (growArea) => {
        let data = Object.values(growArea).findIndex((temp_data) => temp_data.locale === "en_EN");
        return data;
    }
    
    const checkLanguageExists = (offset) => {
        return Object.values(offset).findIndex((tmp)=>{return tmp.locale.includes(props.lang)});
    }

    const textChange = (name, text) => {
        if (name === "sorting") {
            setSorting({ "sorting": text })
        }
        else {
            let check = checkLanguageExists(growAreaText);

            let data1 = { [name]: text };
            let data2 = getLanguage() in growAreaText ? growAreaText[getLanguage()] : [];
            let language = { "locale": languageContent };
            let data3 = { ...data2, ...data1, ...language };

            let data4 = { [check === -1 ? Object.values(Array(growAreaText)[0]).length : check]: data3 };
            let data = { ...growAreaText, ...data4 };

            setGrowAreaText(data);
        }
    }

    const onSaveButtonClick = () => {
        let data0 = { ["org"]: props.organization };
        let grow_area_text = [];
        Object.values(growAreaText).map((tempGrowArea) => {
            if("description" in tempGrowArea === false){
                tempGrowArea = {...tempGrowArea, ["description"]: ""};
            }
            grow_area_text.push({ ...tempGrowArea })
        });

        let data1 = { ["growAreaid"]: selectedGrowAreaId };
        let data2 = { ["growAreaText"]: JSON.stringify(grow_area_text) };
        let data3 = { "growarea_img": file == "" ? "" : file };
        let data4 = { ["is_irrigated"]: edited };

        let tmp_sorting = { "sorting": parseInt(sorting.sorting) };
        let data = { ...data0, ...data1, ...data2, ...data3, ...data4, ...tmp_sorting };

        if (fileTypeError === false) {
            setFileTypeError(false);
            if (fileSizeError === false) {
                setFileSizeError(false);

                props.actions.registerGrowAreaRequest(data);
            }
            else {
                setFileSizeError(true);
            }
        }
        else {
            setFileTypeError(true);
        }
    }

    useEffect(() => {
        setFile(file)
    }, [file]);

    useEffect(() => {
        setFileSizeError(fileSizeError);
        setFileTypeError(fileTypeError);
    }, [fileSizeError, fileTypeError]);

    const editGrowArea = (growArea) => {
        document.getElementById('name').value = getLanguageIndex(growArea.grow_area_text) in growArea.grow_area_text ? growArea.grow_area_text[getLanguageIndex(growArea.grow_area_text)].name : "";
        let data = [];
        growArea.grow_area_text.map((tempData) => {
            data.push({ "locale": tempData.locale, "name": tempData.name, "description": tempData.description })
        });
        setGrowAreaText(data);
    }

    useEffect(() => {
        setGrowAreaText(growAreaText);
    }, [growAreaText]);

    var langContent = getLangContent(props.lang);
    var languageContent = getLanguageContent(props.lang);

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    let to_org = GetToOrganisation(props.mapping_list, props.auth); 

    const renderModal = () => {
        return (
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    closeModal()
                }}
                style={customStyles}
            >
                <h4>Do you really want to delete grow area ?</h4>
                <div className="modalBox">
                    <div className="leftModal">
                        <div
                            className="modalButton"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                props.actions.deleteGrowAreaRequest({ "growAreaid": selectedGrowArea.id, "organisation": props.organization })
                                closeModal()
                            }}
                        >
                            Yes
                        </div>
                    </div>

                    <div className="rightModal">
                        <div
                            className="modalButton"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                closeModal()
                            }}
                        >
                            No
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    const renderGrowArea = () => {
        return (
            <div>
                {
                    languageLength.map((lang, key) => {
                        return (
                            langContent === lang &&
                                <div key={key}>
                                    {
                                        selectedGrowAreaId !== "" ?
                                            <TextBox
                                                label="Grow Area Type"
                                                name="name"
                                                id="name"
                                                onTextChange={textChange}
                                                value={
                                                    getLanguage() in growAreaText === true ?
                                                        growAreaText[getLanguage()].name
                                                        :
                                                        undefined
                                                }
                                                value1={
                                                    languageContent !== 'en_EN' ?
                                                        getLanguage1() in growAreaText ?
                                                            growAreaText[getLanguage1()].name
                                                            :
                                                            undefined
                                                        :
                                                        undefined
                                                }
                                            />
                                            :
                                            <TextBox
                                                label="Grow Area Type"
                                                name="name"
                                                id="name"
                                                onTextChange={textChange}
                                                value={getLanguage() in growAreaText ? growAreaText[getLanguage()].name : undefined}
                                                value1={
                                                    languageContent !== "en_EN" ?
                                                        getLanguage1() in growAreaText ? growAreaText[getLanguage1()].name : undefined
                                                        :
                                                        undefined
                                                }
                                            />
                                    }
                                </div>
                        )
                    })
                }

                {
                    languageLength.map((lang, key) => {
                        return (
                            langContent === lang &&
                            <div key={key}>
                                {
                                    selectedGrowAreaId !== "" ?
                                        <div>
                                            <TextArea
                                                label="Description"
                                                name="description"
                                                id="description"
                                                onTextChange={textChange}
                                                value={
                                                    getLanguage() in growAreaText === true ?
                                                        growAreaText[getLanguage()].description
                                                        :
                                                        undefined
                                                }
                                                value1={
                                                    languageContent !== 'en_EN' ?
                                                        getLanguage1() in growAreaText ?
                                                            growAreaText[getLanguage1()].description
                                                            :
                                                            undefined
                                                        :
                                                        undefined
                                                }
                                            />
                                        </div>
                                        :
                                        <div>
                                            <TextArea
                                                label="Description"
                                                id="description"
                                                name="description"
                                                onTextChange={textChange}
                                                value={getLanguage() in growAreaText ? growAreaText[getLanguage()].description : undefined}
                                                value1={
                                                    languageContent !== "en_EN" ?
                                                        getLanguage1() in growAreaText ? growAreaText[getLanguage1()].description : undefined
                                                        :
                                                        undefined
                                                }
                                            />
                                        </div>
                                }
                            </div>
                        )
                    })
                }

                <div className="TextBoxContainer">
                    <div className="leftSelectLabelContainer1">
                        <span>Irrigation</span> 
                    </div>

                    <div className="rightSelectLabelContainer1">
                        <div style={{display: "flex",flexDirection: 'row'}}>
                            <input type="radio" id="main" name="category" 
                                value="main" 
                                className={edited && "selected"}
                                checked={edited && "checked"} 
                                onChange={()=> setEdited(true)} 
                            />
                            <label>Irrigated</label>

                            <input type="radio" id="sub" name="category" value="sub" 
                                className={edited === false && "selected"}
                                checked={edited === false && "checked"} 
                                onChange={()=> setEdited(false)} 
                            />
                            <label>Rainfed</label>
                        </div>
                    </div>
                </div>

                <div className="TextBoxContainer">
                    <div className="leftSelectLabelContainer1">
                        <span>Add before</span>
                    </div>

                    <div className="rightSelectLabelContainer1">
                        <div style={{ display: "flex", flexDirection: 'column' }}>
                            <select
                                className="selectInput"
                                name="sorting"
                                value={sorting !== undefined && sorting.sorting}
                                onChange={(e) => { textChange(e.target.name, e.target.value) }}
                            >
                                {
                                    growAreaList.length > 0 &&
                                        growAreaList.map((growArea, key) => {
                                            return (
                                                getLanguageIndex(growArea.grow_area_text) in growArea.grow_area_text ?
                                                    getLanguageIndex(growArea.grow_area_text) !== -1 ?
                                                        <option key={key} value={growArea.sorting}>
                                                            {growArea.grow_area_text[getLanguageIndex(growArea.grow_area_text)].name}
                                                        </option>
                                                    :
                                                        undefined
                                                :
                                                    getEnglishLanguageIndex(growArea.grow_area_text) in growArea.grow_area_text ?
                                                        <option key={key} value={growArea.sorting}>
                                                            {growArea.grow_area_text[getEnglishLanguageIndex(growArea.grow_area_text)].name}
                                                        </option>
                                                    :
                                                        undefined
                                            )
                                        })
                                }
                                <option value={parseInt(getLastSortingIndex()) + 1} selected>{`End`}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="leftFormCard">
                    <FileType
                        label={"Grow Area Image"}
                        handleChange={handleChange}
                        error={fileSizeError === true ? "Maximum limit size exceeds 100 KB" : undefined}
                        error1={fileTypeError === true ? ".jpeg and .png file type are only allowed." : undefined}
                        file={
                            Object.keys(selectedGrowArea).length > 0 ?
                                file == "" ?
                                    (selectedGrowArea.growarea_img !== null && selectedGrowArea.growarea_img !== "") &&
                                    selectedGrowArea.growarea_img
                                    :
                                    file
                                :
                                file
                        }
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="characteristicsContainer">
            <Header header={false} />

            <Breadcrumb save={
                (props.user_role_type === "Superuser" && props.organisation === to_org) ? 
                    true 
                : 
                    (props.user_role_type === "Superuser" && props.umva_id === props.superuser)  ? 
                        true
                    :
                        false
                } 
                onSaveButtonClick={onSaveButtonClick} 
            />

            <div className="generBreadcrumbalBody" style={{marginTop: 40}}>
                <ToastContainer />
                {
                    props.grow_area_list_loading === true || props.register_grow_area_loading === true || props.delete_grow_area_loading === true ?
                        <Spinner />
                        :
                        <div className="formCard" style={{ flexDirection: 'column' }}>
                            {
                                (props.user_role_type === "Superuser" && props.umva_id === props.superuser) ?
                                    renderGrowArea()
                                :
                                    (props.user_role_type === "Superuser" && props.organisation === to_org) &&   
                                        renderGrowArea()
                            }

                            <table className="tableContainer" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>Irrigation</th>
                                        <th></th>
                                        <th>Grow Area Type</th>
                                        {
                                            (props.user_role_type === "Superuser" && props.umva_id === props.superuser) ?
                                                <>
                                                    <th>
                                                        <span>Edit</span>
                                                    </th>
                                                    <th>
                                                        <span>Delete</span>
                                                    </th>
                                                </>
                                            :
                                                (props.user_role_type === "Superuser" && props.organisation === to_org) &&    
                                                    <>
                                                        <th>
                                                            <span>Edit</span>
                                                        </th>
                                                        <th>
                                                            <span>Delete</span>
                                                        </th>
                                                    </>
                                        }
                                    </tr>
                                </thead>
                                {
                                    growAreaList.length > 0 &&
                                    growAreaList.map((growArea, key) => {
                                        return (
                                            <tbody key={key} className={selectedGrowAreaId !== "" ? growArea.id === selectedGrowAreaId ? "selectedRow" : "" : ""}>
                                                <tr key={key}>
                                                    <td>{growArea?.is_irrigated === true ? "Irrigated" : growArea?.is_irrigated === false ? "Rainfed" : "" }</td>
                                                    <td>
                                                        {
                                                            "growarea_img" in growArea === true &&
                                                                (growArea.growarea_img !== null && growArea.growarea_img !== "") ?
                                                                <img
                                                                    src={`data:image/png;base64, ${growArea.growarea_img}`}
                                                                    alt="growarea"
                                                                    className="headerImage"
                                                                />
                                                                :
                                                                <div className="headerImage"></div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            getLanguageIndex(growArea.grow_area_text) in growArea.grow_area_text ?
                                                                getLanguageIndex(growArea.grow_area_text) !== -1 ?
                                                                    growArea.grow_area_text[getLanguageIndex(growArea.grow_area_text)].name
                                                                    :
                                                                    undefined
                                                                :
                                                                undefined
                                                        }
                                                    </td>
                                                    {
                                                        (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                                                            <>
                                                                <td style={{ justifyContent: "center", alignItems: "center" }}>
                                                                    <img
                                                                        src={require('../../../images/edit.png')}
                                                                        alt="edit"
                                                                        style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            window.scrollTo(0, 0);
                                                                            document.getElementById('name').value = ""
                                                                            setSorting({ "sorting": growArea.sorting })
                                                                            editGrowArea(growArea)
                                                                            setSelectedGrowArea(growArea)
                                                                            setSelectedGrowAreaId(growArea.id)
                                                                            setFile(growArea.growarea_img)
                                                                            setEdited(growArea?.is_irrigated)
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td style={{ justifyContent: "center", alignItems: "center" }}>
                                                                    <img
                                                                        src={require('../../../images/delete.png')}
                                                                        alt="delete"
                                                                        style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            e.preventDefault()
                                                                            setSelectedGrowArea(growArea);
                                                                            openModal();
                                                                        }}
                                                                    />
                                                                </td>
                                                            </>
                                                        :
                                                            (props.user_role_type === "Superuser" && props.umva_id === props.superuser) &&      
                                                                <>
                                                                    <td style={{ justifyContent: "center", alignItems: "center" }}>
                                                                        <img
                                                                            src={require('../../../images/edit.png')}
                                                                            alt="edit"
                                                                            style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                window.scrollTo(0, 0);
                                                                                document.getElementById('name').value = ""
                                                                                setSorting({ "sorting": growArea.sorting })
                                                                                editGrowArea(growArea)
                                                                                setSelectedGrowArea(growArea)
                                                                                setSelectedGrowAreaId(growArea.id)
                                                                                setFile(growArea.growarea_img)
                                                                                setEdited(growArea?.is_irrigated)
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ justifyContent: "center", alignItems: "center" }}>
                                                                        <img
                                                                            src={require('../../../images/delete.png')}
                                                                            alt="delete"
                                                                            style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                e.preventDefault()
                                                                                setSelectedGrowArea(growArea);
                                                                                openModal();
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </>
                                                    }
                                                </tr>
                                                {
                                                    getLanguageIndex(growArea.grow_area_text) in growArea.grow_area_text ?
                                                        getLanguageIndex(growArea.grow_area_text) !== -1 ?
                                                            <tr>
                                                                <td colSpan="6">
                                                                    {
                                                                        growArea.grow_area_text[getLanguageIndex(growArea.grow_area_text)].description
                                                                    }
                                                                </td>
                                                            </tr>
                                                            :
                                                            undefined
                                                        :
                                                        undefined
                                                }
                                            </tbody>
                                        )
                                    })
                                }
                            </table>

                            {
                                (props.user_role_type === "Superuser" && props.organisation === to_org) ?
                                    renderModal()
                                :
                                    (props.user_role_type === "Superuser" && props.umva_id === props.superuser) &&
                                        renderModal()
                            }
                        </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        lang: state.lang.language,
        grow_area_list: state.growArea.grow_area_list,
        selected_grow_area: state.growArea.selected_grow_area,
        updateActivityMedia: state.growArea.updateActivityMedia,
        organization: state.auth.organization,
        grow_area_list_loading: state.growArea.grow_area_list_loading,
        register_grow_area_loading: state.growArea.register_grow_area_loading,
        delete_grow_area_loading: state.growArea.delete_grow_area_loading,
        user_role_type: state.auth.user_role_type,
        language_list: state.lang.language_list,
        mapping_list: state.organization.mapping_list,
        auth: state.auth.organization,
        organisation: state.auth.organization,
        superuser: state.auth.superuser,
        umva_id: state.auth.umva_id,
    };
};

function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                growAreaListRequest,
                selectGrowArea,
                removeSelectedGrowArea,
                languageUpdate,
                registerGrowAreaRequest,
                deleteGrowAreaRequest
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Index);