import OrganizationActionTypes from './actionTypes';

export function organizationListRequest(){
    return {
        type: OrganizationActionTypes.ORGANIZATION_LIST_REQUEST
    }
}

export function selectOrganization(org){
    return {
        type: OrganizationActionTypes.ORGANIZATION_SELECT_REQUEST,
        org
    }
}

export function offlineOrganizationRequest(data){
    return {
        type: OrganizationActionTypes.ORGANIZATION_OFFLINE_REQUEST,
        data
    }
}

export function registerOrganisation(params){
    return {
        type: OrganizationActionTypes.ORGANIZATION_ADD_REQUEST,
        params
    }
}

export function deleteOrganisation(org_id, country){
    return {
        type: OrganizationActionTypes.ORGANIZATION_DELETE_REQUEST,
        org_id,
        country
    }
}

export function removeSelectedOrganisation(){
    return {
        type: OrganizationActionTypes.REMOVE_SELECTED_ORGANISATION
    }
}

export function countryListRequest(){
    return {
        type: OrganizationActionTypes.COUNTRY_LIST_REQUEST
    }
}

export function getOrganisationList() {
    return {
        type: OrganizationActionTypes.ORGANISATION_LIST_REQUEST
    }
}

export function selectLocaleOrganization(org){
    return {
        type: OrganizationActionTypes.ORGANISATION_LOCALE_REQUEST,
        org
    }
}

export function organisationMappingList(){
    return {
        type: OrganizationActionTypes.ORAGNISATION_MAPPING_LIST_REQUEST
    }
}

export function countryRequest(){
    return {
        type: OrganizationActionTypes.COUNTRY_REQUEST
    }
}

export function organizationRequest(){
    return {
        type: OrganizationActionTypes.ORGANIZATION_REQUEST
    }
}