import React, { useEffect, useState } from 'react';
import Header from '../../../common/header';
import Breadcrumb from '../../../common/breadcrumb';
import '../../crop/view/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    organizationListRequest,
    selectOrganization,
    registerOrganisation,
    deleteOrganisation,
    removeSelectedOrganisation,
    countryListRequest,
    getOrganisationList,
    selectLocaleOrganization
} from '../action';
import { roleRequest } from '../../role/action';
import Spinner from '../../../common/spinner';
import { ToastContainer } from 'react-toastify';
import Modal, { defaultStyles } from 'react-modal';
import TextBox from '../../../common/textBox';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root');

const Index = (props) => {
    let tempLocale = [ {
        locale: "en_EN",
        video_locale: "",
        language_name: "English"
    }];
    var language_list = [
        { "value": "adh_ADH", "language": "Adhola" },
        { "value": "ar_AR", "language": "Arabic" },
        { "value": "teo_TEO", "language": "Ateso" },
        { "value": "nl_NL", "language": "Dutch" },
        { "value": "fr_FR", "language": "French" },
        { "value": "en_EN", "language": "English" },
        { "value": "rn_KI", "language": "Kirundi" },
        { "value": "kon_KON", "language": "Kongo" },
        { "value": "lg_UG", "language": "Luganda" },
        { "value": "sw_SW", "language": "Swahili" },
        { "value": "mos_MOS", "language": "Mooré" },
        { "value": "dyu_DYU", "language": "Dyula" },
        { "value": "bis_BIS", "language": "Bissa" },
        { "value": "ful_FUL", "language": "Fulah" },
        { "value": "ach_UG", "language": "Acholi" },
    ];
    const [languageList,setLanguageList] = useState(language_list);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [organizationData, setOrganizationData] = useState({});
    const [organizationList, setOrganizationList] = useState(props.organization_list);
    const [errorOrganisation, setErrorOrganization] = useState(false);
    const [errorDefaultLocale, setErrorDefaultLocale] = useState(false);
    const [errorLatLng, setErrorLatLng] = useState([]);
    const [errorVideoLocale, setErrorVideoLocale] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [countires, setCountires] = useState(props.countries);
    const [tempcountires, setTempCountires] = useState([]);

    const [selectedOrganisation, setSelectedOrganisation] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [organisationName, setOrganisationName] = useState("");
    const [language, setLanguage] = useState(["en_EN"]);
    const [videoLanguage, setVideoLanguage] = useState([]);
    const [defaultLocale, setDefaultLocale] = useState("");
    const [, setVideoLocale] = useState([]);
    const [, setSelectedOrganisationId] = useState("");
    const [languageCounter,] = useState(0);
    const [locale, setLocale] = useState([]);
    const [mode, setMode] = useState('');

    useEffect(() => {
        if (Object.keys(props.selected_organization).length > 0) {
            //setSelectedCountry(props.selected_organization.to_organisation);

            if ("id" in props.selected_organization) {
                setSelectedOrganisationId(props.selected_organization.id);
            }
            if ("organisation" in props.selected_organization) {
                setOrganisationName(props.selected_organization.organisation);
            }
            if ("default_locale" in props.selected_organization) {
                setDefaultLocale(props.selected_organization.default_locale);
            }

            setLanguage(props.selected_organization.locale);

            if (props.selected_organization.mapping.length > 0) {
                let temp = [];

                Object.values(props.selected_organization.mapping).map((temp_mapping) => {
                    let temp_data = {
                        locale: Object.keys(temp_mapping).length > 0 ? Object.keys(temp_mapping)[0] : "",
                        video_locale: Object.values(temp_mapping).length > 0 ? Object.values(temp_mapping)[0] : "",
                        language_name: Object.values(temp_mapping).length > 0 ? getLanguageName(Object.values(temp_mapping)[0]) : "",
                    }
                    temp.push(temp_data);
                })

                setLocale(temp);
            }
        }
    }, [props.selected_organization_loading, props.selected_organization]);

    useEffect(() => {
        languageList.map((text) => {
            if (Object.values(props.selected_organization).length > 0) {
                setLatitude(props.selected_organization.latitude);
                setLongitude(props.selected_organization.longitude);

                if (props.selected_organization.locale.includes(text.value) === false) {
                    for (let j = 0; j < languageList.length; j++) {
                        document.getElementById(text.value + j).checked = false;
                    }
                }
            }
        });
    }, [props.selected_organization]);

    useEffect(() => {
        setOrganisationName("");
        setLanguage(["en_EN"]);
        setDefaultLocale("");
        setVideoLocale([]);
        setSelectedOrganisationId("");
        setLocale([
            {
                locale: "en_EN",
                video_locale: "",
                language_name: "English"
            }
        ]);
    }, [
        props.organisation_delete_loading,
        props.organization_list_loading,
        props.organisation_register_loading
    ]);

    useEffect(() => {
        props.actions.countryListRequest();
        props.actions.removeSelectedOrganisation();
        props.actions.getOrganisationList();
    }, []);

    useEffect(() => {
        props.actions.roleRequest();
    }, [props.actions]);

    useEffect(() => {
        setOrganizationList(props.organization_list);
    }, [props.organization_list]);

    useEffect(() => {
        setVideoLanguage(videoLanguage);
    }, [languageCounter]);

    const getLanguageName = (locale) => {
        let data = languageList.filter((temp) => { return temp.value === locale });

        if (data.length > 0) {
            return data[0].language;
        }
    }

    const getLanguageNameFromOffset = (offset) => {
        let index = language_list.findIndex((tmp)=>{return tmp.value === offset});
        if(index !== -1){
            return language_list[index].language;
        }
        else{
            return "English";
        }
    }

    const checkLocale = (offset) => {
        if(Object.values(props.selected_organization).length === 0){
            let data = Object.values(locale).findIndex((temp) => { return temp.locale === offset });
            return data;
        }
        else{
            if(mode === "edit"){
                let data = Object.values(locale).findIndex((temp) => { return temp.locale === offset });
                return data;
            }
            else{
                let data = props.selected_organization.locale.findIndex((temp) => { return temp === offset });
                return data;
            }
        }
    }

    const CommonOrganisations = (added_organisation) => {
        let commonOrganisations = Object.values(props.organisation_list).filter((tmpOrg) => {
            return tempcountires.includes(tmpOrg.bank_name);
        });
        
        if (Object.values(commonOrganisations).length > 0) {
            let orgList = [];
            Object.values(commonOrganisations).map((tmp) => {
                orgList.push(tmp.bank_name);
            });

            if (orgList.length > 0) {
                let diffOrganisation = tempcountires.filter((tmp) => {
                    return !orgList.includes(tmp);
                });

                if (diffOrganisation.length > 0) {
                    orgList = [...orgList, ...diffOrganisation];
                }

                if (!orgList.includes(added_organisation)) {
                    orgList.push(added_organisation);
                    setCountires(orgList);
                }
                else {
                    let common = tempcountires.filter((tmp) => {
                        return orgList.includes(tmp);
                    });
                    setCountires(common);
                }
            }
        }
        else{
            let orgList = props.countries;
            orgList.push(added_organisation);
            setCountires(orgList);
        }
    }

    const textChange = (name, text) => {
        setErrorDefaultLocale(false);

        let data1 = {};

        if (name === "country") {
            setMode('select');
            setCountryError(false);
            let index = organizationList.findIndex((tmp) => { return tmp.organisation === text });

            if (index !== -1) {
                let temp_selected_organisation = organisationName;
                props.actions.selectLocaleOrganization(organizationList[index]);
                setOrganisationName(temp_selected_organisation);
            }

            if (text !== "Choose") {
                setSelectedCountry(text);
            }
        }

        if (name === "organisation") {
            setOrganisationName(text);
            CommonOrganisations(text);
        }

        if (name === "latitude") {
            if (Object.values(errorLatLng).length > 0) {
                let tempError = { ...errorLatLng };
                tempError.latitude = "";
                setErrorLatLng(tempError)
            }
            setLatitude(text);
        }

        if (name === "longitude") {
            if (Object.values(errorLatLng).length > 0) {
                let tempError = { ...errorLatLng };
                tempError.longitude = "";
                setErrorLatLng(tempError);
            }
            setLongitude(text);
        }

        if (name === "locale") {
            if (text !== "en_EN") {
                let tmp_lanaguage = [];

                if(Object.values(props.selected_organization).length > 0){
                    tmp_lanaguage = props.selected_organization.locale;
                }
                else{
                    tmp_lanaguage = language;
                }

                var index = Object.values(locale).findIndex((temp) => { return temp.locale === text });
                var languageIndex = Object.values(tmp_lanaguage).findIndex((temp) => { return temp === text });
                var data = [...locale];
                var temp_language = tmp_lanaguage;

                if (Object.values(locale).length > 0) {
                    if (index === -1) {
                        let temp_locale = {
                            locale: text,
                            video_locale: "",
                            language_name: getLanguageName(text),
                        };

                        data.push(temp_locale);

                        setLocale(data);

                        temp_language.push(text);
                        setLanguage(temp_language);
                    }
                    else {
                        data.splice(index, 1);
                        temp_language.splice(languageIndex, 1);
                        setLocale(data);
                        setLanguage(temp_language);
                    }
                }
                else {
                    let temp_locale = {
                        locale: text,
                        video_locale: "",
                        language_name: getLanguageName(text)
                    };
                    setLocale([temp_locale]);
                    setLanguage([text]);
                }
            }
        }
        else if (name == "video_locale") {
            let data = [...locale];

            if(Object.values(locale).length > 0){
                let en_index = Object.values(locale).findIndex((temp) => { return temp.locale === "en_EN" });
                if(en_index !== -1){
                    data[en_index].language_name = "English";
                }
            }
            
            let index = Object.values(locale).findIndex((temp) => { return temp.locale === text[0] });
            let language = getLanguageName(text[0]);

            if (index !== -1) {
                data[index].video_locale = text[1];
                data[index].language_name = language;
                setLocale(data);
            }
        }
        else {
            if (name == "default_locale") {
                setDefaultLocale(text);
                setErrorDefaultLocale(false);
            }
            else {
                setErrorOrganization(false);
            }
            let data = { [name]: text };
            data1 = { ...organizationData, ...data };
        }

        setOrganizationData(data1);
    }

    const getCountry = (org) => {
        if(props.organisation_list !== undefined ){
            let temp_org =  props.organisation_list.filter((tmp)=>{return tmp.bank_code === org});

            if(Object.values(temp_org).length > 0){
                if(org === "AUXFIN-BF"){
                    return "BF";
                }
                else{
                    return temp_org[0].country;
                }
            }
            else{
                return "";
            }
        }
        else
            return "";
    }

    const onSaveButtonClick = () => {
        setErrorOrganization(false);
        setErrorDefaultLocale(false);
        setCountryError(false);
        setErrorVideoLocale(false);
        setErrorLatLng({});

        let data1 = {
            ...organizationData,
            ...{ "to_org": Object.values(props.selected_organization).length > 0 ? !selectedCountry ? props.selected_organization.organisation : selectedCountry : selectedCountry },
            ...{ "language": JSON.stringify(locale) },
            ...{ "default_locale": defaultLocale },
            ...{ "organisation": organisationName },
            ...{ "latitude": latitude },
            ...{ "longitude": longitude },
            ...{ "country": getCountry(organisationName) },
            ...{ "superuser": props.umva_id }
        };

        if ("id" in props.selected_organization) {
            data1 = {
                ...data1,
                ...{
                    "default_locale": defaultLocale,
                    "language": JSON.stringify(locale),
                    "organisation": organisationName,
                },
                ...{ "to_org": Object.values(props.selected_organization).length > 0 ? !selectedCountry ? props.selected_organization.organisation : selectedCountry : selectedCountry },
            };
        }
        else {
            data1 = {
                ...data1
            };
        }

        if (!"organisation" in data1) {
            setErrorOrganization(true);
        }
        else {
            if (["", undefined, "NULL", "Choose"].includes(data1.organisation)) {
                setErrorOrganization(true);
            }
        }

        if (!"to_org" in data1) {
            if ([undefined, "", null, "Choose"].includes(data1.country) === true) {
                setCountryError(true);
            }
        }
        else {
            if ([undefined, "", null, "Choose"].includes(data1.country) === true) {
                setCountryError(true);
            }
        }

        if (!"default_locale" in data1) {
            setErrorDefaultLocale(true);
        }
        else {
            if (data1.default_locale === "Choose") {
                setErrorDefaultLocale(true);
            }
        }

        if (!"default_locale" in data1) {
            setErrorDefaultLocale(true);
        }
        else {
            if ((["NULL", "", "Choose", undefined].includes(data1.default_locale) === true)) {
                setErrorDefaultLocale(true);
            }
        }

        if (!"latitude" in data1) {
            setErrorLatLng(value => ({
                ...value,
                ['latitude']: 'Latitude in required.'
            }));
        }
        else {
            if (data1.latitude == "") {
                setErrorLatLng(value => ({
                    ...value,
                    ['latitude']: 'Latitude in required.'
                }));
            }
        }

        if (!"longitude" in data1) {
            setErrorLatLng(value => ({
                ...value,
                ['longitude']: 'Longitude in required.'
            }));
        }
        else {
            if (data1.longitude === "") {
                setErrorLatLng(value => ({
                    ...value,
                    ['longitude']: 'Longitude in required.'
                }));
            }
        }

        let error_video = [];
        locale.map(async(tmp)=>{
            if(tmp.video_locale === ""){
                error_video.push(false);
            }
        })

        if(error_video.includes(false)){
            setErrorVideoLocale(true);
        }

        if (
            !error_video.includes(false) &&
            ((["NULL", undefined, ""].includes(data1.organisation) === false) && ("organisation" in data1 === true)) &&
            (selectedCountry !== "") &&
            (["NULL", "", "Choose", undefined].includes(data1.default_locale) === false &&
                data1.latitude !== "" && data1.longitude !== "")
        ) {
            setSelectedCountry("");
            data1 = {...data1, "org_id": props.selected_organization.id === undefined ? "" : props.selected_organization.id};
            props.actions.registerOrganisation(data1);
        }
    }

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    const getSelectedLocale = (key, value) => {
        let data = false;

        if (Object.values(props.selected_organization).length > 0) {
            props.selected_organization.mapping.map((tmp) => {
                if (Object.keys(tmp).includes(key) === true && Object.values(tmp).includes(value) === true) {
                    data = true;
                }
            })
        }
        return data;
    }

    const updateToOrganisation = (addedOrganisation) => {
        let to_organisation = props.mapping_list.filter((tmp)=>{return tmp.from === addedOrganisation});

        if(to_organisation.length > 0){
            setSelectedCountry(to_organisation[0].to);
        }

        let tempCountires = [...countires];

        let index = tempCountires.includes(addedOrganisation);
        if (!index) {
            tempCountires.push(addedOrganisation);
            setCountires(tempCountires);
        }
    }

    useEffect(() => {
        setCountires(props.countries);
        setTempCountires(props.countries);
    }, [props.countries_list_loading]);


    useEffect(() => {
        let temp_locale_data = [];

        if(props.selected_organization !== undefined){
            if(Object.values(props.selected_organization).length > 0){
                Object.keys(props.selected_organization.mapping).map(async (tmp, i)=>{
                    let locale_data = Object.keys(props.selected_organization.mapping[i])[0];

                    temp_locale_data.push({
                        video_locale: Object.values(props.selected_organization.mapping[tmp])[0],
                        locale: locale_data,
                        language_name: getLanguageNameFromOffset(locale_data),
                    });
                });
                setLocale(temp_locale_data);
            }   
            else{
                setLocale(tempLocale);
            }

            setDefaultLocale(props.selected_organization.default_locale);
        }
        else{
            setLocale(tempLocale);
        }

        // if (props.selected_organization.organisation !== undefined) {
        //     setSelectedCountry(props.selected_organization.organisation);
        // }
    }, [props.selected_organization]);

    const checkSelect = (lng, language_data) => {
        let check = locale.filter((tmp)=>{return tmp.locale === lng});

        if(check.length > 0){
            if(check[0].video_locale !== ""){
                if(language_data === check[0].video_locale){
                    if(check[0].video_locale === language_data){
                        if(language.includes(language_data) === false){
                            let index = locale.findIndex((tmp)=>{return tmp.locale === lng});
                            if(index !== -1){
                                locale[index].video_locale = "";
                            }
                            //return false;
                            return true;
                        }
                        else    
                            return true;
                    }
                    else
                        return true; 
                }
                else    
                    return false;
            }
            else
                return false;
        }
        else
            return false;
    }

    return (
        <div className="characteristicsContainer">
            <Header header={false} displayLanguage={false} />

            <Breadcrumb save={props.user_role_type === "Superuser" ? true : false} onSaveButtonClick={onSaveButtonClick} />

            <div className="generalBody" style={{ flexDirection: 'column' }}>
                <ToastContainer />
                {
                    props.organisation_delete_loading === true || props.organization_list_loading === true || props.organisation_register_loading === true ?
                        <Spinner />
                        :
                        <div className="formCard" style={{ flexDirection: 'row', flex: 1 }}>
                            <div style={{ flexDirection: 'row', flex: 1 }}>
                                {
                                    props.user_role_type === "Superuser" &&
                                        <div className="leftFormCard" style={{ width: '100%' }}>

                                            <div className="TextBoxContainer">
                                                <div className="leftSelectLabelContainer1">
                                                    <span>Organisation Name</span>
                                                </div>

                                                <div className="rightSelectLabelContainer1">
                                                    <div style={{ display: "flex", flexDirection: 'column' }}>
                                                        <div style={{ display: "flex", flexDirection: 'column' }}>
                                                            {
                                                                errorOrganisation === true &&
                                                                    <span className="errorText">{"Organisation name is required"}</span>
                                                            }
                                                            <select
                                                                value={organisationName === "Auxfin Burundi" ? "PNSEB-BURUNDI" : organisationName}
                                                                id="organisation"
                                                                className="selectInput"
                                                                name="organisation"
                                                                onChange={(e) => { textChange("organisation", e.target.value) }}
                                                            >
                                                                <option value={"Choose"}>{"Choose"}</option>
                                                                {
                                                                    (props.organisation_list !== undefined  && countires !== undefined) &&
                                                                        Object.values(props.organisation_list).length > 0 &&  Object.values(countires).length > 0  &&
                                                                            props.organisation_list.map((organisation, key) => {
                                                                                return (
                                                                                    organisation.status &&
                                                                                        <option
                                                                                            key={key}
                                                                                            value={organisation.code === "Auxfin Burundi" ? "PNSEB-BURUNDI" : organisation.code}
                                                                                        >
                                                                                            {organisation.bank_name}
                                                                                        </option>
                                                                                )
                                                                            })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div style={{ display: "flex", flexDirection: 'column' }}>
                                                <TextBox
                                                    value={organisationName === "Auxfin Burundi" ? "PNSEB-BURUNDI" : organisationName}
                                                    label="Organization Name"
                                                    name="organisation"
                                                    id="organisation"
                                                    onTextChange={textChange}
                                                    error={ errorOrganisation === true ? "Organisation name is required": "" }
                                                />
                                            </div> */}

                                            <div className="TextBoxContainer">
                                                <div className="leftSelectLabelContainer1">
                                                    <span>To Organisation</span>
                                                </div>

                                                <div className="rightSelectLabelContainer1">
                                                    <div style={{ display: "flex", flexDirection: 'column' }}>
                                                        <div style={{ display: "flex", flexDirection: 'column' }}>
                                                            {
                                                                countryError === true &&
                                                                <span className="errorText">{"To Organisation is required."}</span>
                                                            }
                                                            <select
                                                                value={selectedCountry}
                                                                id="country"
                                                                className="selectInput"
                                                                name="country"
                                                                onChange={(e) => { textChange("country", e.target.value) }}
                                                            >
                                                                <option value={"Choose"}>{"Choose"}</option>
                                                                {
                                                                    countires !== undefined &&
                                                                    Object.values(countires).length > 0 &&
                                                                    countires.map((country, key) => {
                                                                        return (
                                                                            <option key={key} value={country}>{country}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ display: "flex", flexDirection: 'column' }}>
                                                <TextBox
                                                    value={Object.values(props.selected_organization).length ? props.selected_organization.latitude : ""}
                                                    label="Latitude"
                                                    name="latitude"
                                                    id="latitude"
                                                    onTextChange={textChange}
                                                    type="latLng"
                                                    error={
                                                        Object.values(errorLatLng).length > 0 ?
                                                            ("latitude" in errorLatLng) ? errorLatLng.latitude !== "" ? errorLatLng.latitude : "" : ""
                                                            : ""
                                                    }
                                                />
                                            </div>

                                            <div style={{ display: "flex", flexDirection: 'column' }}>
                                                <TextBox
                                                    value={Object.values(props.selected_organization).length ? props.selected_organization.longitude : ""}
                                                    label="Longitude"
                                                    name="longitude"
                                                    id="longitude"
                                                    onTextChange={textChange}
                                                    type="latLng"
                                                    error={
                                                        Object.values(errorLatLng).length > 0 ?
                                                            ("latitude" in errorLatLng) ? errorLatLng.longitude !== "" ? errorLatLng.longitude : "" : ""
                                                            : ""
                                                    }
                                                />
                                            </div>

                                            {
                                                (errorDefaultLocale === true) &&
                                                <span className="errorText">{"Default locale is needed."}</span>
                                            }
                                            {
                                                (errorVideoLocale === true) &&
                                                <span className="errorText">{"Video locale is needed."}</span>
                                            }
                                            <table style={{ width: "100%" }} className="tableContainer" cellSpacing="0">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        {
                                                            languageList.map((tmp) => {
                                                                return (
                                                                    <th>{tmp.value} ({tmp.language})</th>
                                                                )
                                                            })
                                                        }
                                                    </tr>

                                                    <tr>
                                                        <th>locale</th>
                                                        <th>locale</th>
                                                        <th>default</th>
                                                        {
                                                            languageList.map((tmp) => {
                                                                return (
                                                                    <th>videos</th>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        languageList.map((tmp, key) => {
                                                            return (
                                                                <tr>
                                                                    <td>{tmp.value} ({tmp.language})</td>
                                                                    <td>
                                                                        <label className="container" style={{ display: 'inline' }}>
                                                                            <input
                                                                                id={"language" + key}
                                                                                type="checkbox"
                                                                                value={tmp.value}
                                                                                className="selectInput"
                                                                                name="locale"
                                                                                onChange={(e) => { textChange("locale", e.target.value) }}
                                                                                checked={checkLocale(tmp.value) !== -1 ? true : false}
                                                                                disabled={((selectedCountry !== undefined) && Object.values(props.selected_organization).length > 0) 
                                                                                    ?  false : false
                                                                                }
                                                                            />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <label className="radioContainer">
                                                                            <input
                                                                                type="radio"
                                                                                id="default_locale"
                                                                                value={tmp.value}
                                                                                checked={
                                                                                    Object.values(props.selected_organization).length === 0 ?
                                                                                        tmp.value === defaultLocale ?
                                                                                            true
                                                                                            :
                                                                                            false
                                                                                        :
                                                                                        defaultLocale === "" ?
                                                                                            props.selected_organization.default_locale == tmp.value ?
                                                                                                true
                                                                                                :
                                                                                                false
                                                                                            :
                                                                                            tmp.value === defaultLocale ?
                                                                                                true
                                                                                                :
                                                                                                false
                                                                                }
                                                                                name="default_locale"
                                                                                onChange={(e) => { textChange("default_locale", e.target.value) }}
                                                                                disabled={
                                                                                    ((selectedCountry !== undefined) && Object.values(props.selected_organization).length > 0) 
                                                                                        ? mode === 'select' ? true : false
                                                                                        : (language.includes(tmp.value) === true) ? false : true
                                                                                }
                                                                            />
                                                                            <span className="radioCheckmark"></span>
                                                                        </label>
                                                                    </td>
                                                                    {
                                                                        languageList.map((tmp_data, key1) => {
                                                                            return (
                                                                                <td>
                                                                                    <label className="radioContainer">
                                                                                        {
                                                                                            Object.values(props.selected_organization).length === 0 ?
                                                                                                <input
                                                                                                    value={tmp_data.value}
                                                                                                    type="radio"
                                                                                                    name={tmp.value}
                                                                                                    id={tmp.value + key1}
                                                                                                    onChange={(e) => { textChange("video_locale", [tmp.value, e.target.value]) }}
                                                                                                    disabled={((selectedCountry !== undefined) && Object.values(props.selected_organization).length > 0) 
                                                                                                        ? false
                                                                                                        : (language.includes(tmp.value) === true && language.includes(tmp_data.value) === true) ? false : true
                                                                                                    }
                                                                                                    checked={checkSelect(tmp.value, tmp_data.value)}
                                                                                                />
                                                                                                :
                                                                                                <input
                                                                                                    value={tmp_data.value}
                                                                                                    type="radio"
                                                                                                    name={tmp.value}
                                                                                                    id={tmp.value + key1}
                                                                                                    defaultChecked={
                                                                                                        props.selected_organization.locale.includes(tmp.value) === true &&
                                                                                                        getSelectedLocale(tmp.value, tmp_data.value)
                                                                                                    }
                                                                                                    onChange={(e) => { textChange("video_locale", [tmp.value, e.target.value]) }}
                                                                                                    disabled={((selectedCountry !== undefined) && Object.values(props.selected_organization).length > 0) 
                                                                                                        ? false
                                                                                                        : (language.includes(tmp.value) === true && language.includes(tmp_data.value) === true) ? false : true
                                                                                                    }
                                                                                                    checked={checkSelect(tmp.value, tmp_data.value)}
                                                                                                />
                                                                                        }
                                                                                        <span className="radioCheckmark"></span>
                                                                                    </label>
                                                                                </td>
                                                                            )
                                                                        })
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                }

                                <table style={{ width: "50%" }} className="tableContainer" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Organization Name</th>
                                            <th>Latitude</th>
                                            <th>Longitude</th>
                                            <th>
                                                <span>Edit</span>
                                            </th>
                                            <th>
                                                <span>Delete</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    {
                                        organizationList.length > 0 &&
                                            organizationList.map((organization, key) => {
                                                return (
                                                    <tbody className={Object.values(props.selected_organization).length > 0 ? organization.organisation === props.selected_organization.organisation ? "selectedRow" : "" : ""}>
                                                        <tr key={key}>
                                                            <td>{organization.organisation}</td>
                                                            <td>{organization.latitude}</td>
                                                            <td>{organization.longitude}</td>
                                                            <td style={{ justifyContent: "center", alignItems: "center" }}>
                                                                <img
                                                                    src={require('../../../images/edit.png')}
                                                                    alt="edit"
                                                                    style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setOrganisationName("");
                                                                        setSelectedCountry([]);
                                                                        setSelectedOrganisation("");
                                                                        setMode('edit');
                                                                        window.scrollTo(0, 0);
                                                                        updateToOrganisation(organization.organisation);
                                                                        document.getElementById('organisation').value = organization.organisation;
                                                                        props.actions.selectOrganization(organization);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{ justifyContent: "center", alignItems: "center" }}>
                                                                <img
                                                                    src={require('../../../images/delete.png')}
                                                                    alt="delete" selected_organization
                                                                    style={{ width: 20, height: 20, cursor: "pointer" }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        e.preventDefault()
                                                                        setSelectedOrganisation(organization);
                                                                        openModal();
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            })
                                    }
                                </table>

                                {
                                    props.user_role_type === "Superuser" &&
                                    <Modal
                                        isOpen={modalIsOpen}
                                        onRequestClose={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            closeModal()
                                        }}
                                        style={customStyles}
                                    >
                                        <h4>Do you really want to delete {selectedOrganisation.organisation} organisation ?</h4>
                                        <div className="modalBox">
                                            <div className="leftModal">
                                                <div
                                                    className="modalButton"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        setSelectedCountry("");
                                                        props.actions.deleteOrganisation({ "org_id": selectedOrganisation.id, "country": selectedOrganisation.country})
                                                        closeModal();
                                                    }}
                                                >
                                                    Yes
                                                </div>
                                            </div>

                                            <div className="rightModal">
                                                <div
                                                    className="modalButton"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        closeModal()
                                                    }}
                                                >
                                                    No
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                }
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        roleList: state.role.role,
        organization_list: state.organization.organization_list,
        selected_organization: state.organization.selected_organization,
        organization_list_loading: state.organization.organization_list_loading,
        organisation_register_loading: state.organization.organisation_register_loading,
        user_role_type: state.auth.user_role_type,
        organisation_delete_loading: state.organization.organisation_delete_loading,
        countries: state.organization.countries,
        organisation_list: state.organization.organisation_list,
        countries_list_loading: state.organization.countries_list_loading,
        selected_organization_loading: state.organization.selected_organization_loading,
        umva_id: state.auth.umva_id,
        mapping_list: state.organization.mapping_list,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                organizationListRequest,
                selectOrganization,
                roleRequest,
                registerOrganisation,
                deleteOrganisation,
                removeSelectedOrganisation,
                countryListRequest,
                getOrganisationList,
                selectLocaleOrganization
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Index);
