import React,{ useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './cropContainer.css';
import { particularCropListRequest, cropPublishUnpublishRequest, cropSelectUnselectRequest} from '../../action';
import { removeDescription, removeMedia } from '../../action';
import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { seasonListRequest } from '../../../season/action';
import { growAreaListRequest } from '../../../growArea/action';
import CropImages from '../../../../images/images';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

const CropContainer = (props) => {
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [selectedCrop, setSelectedCrop] = React.useState('');

    const getLangContent = (language) => {
        let data =  props.language_list.findIndex((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }

    const getLanguageContent = (language) => {
        let data =  props.language_list.filter((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data.length > 0){
            return data[0].locale;
        }
        else{
            return "en_EN";
        }
    }

    useEffect(()=>{
        setSelectedCrop(selectedCrop);
    },[selectedCrop]);

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    var langContent = getLangContent(props.lang);
    var languageContent = getLanguageContent(props.lang);

    const selectUnselectCrop = (crop_id) => {
        props.selectedCropList(crop_id);
    }

    const seasonListRequest = (selected_crop_id) =>{
        let index = props.organization_list.findIndex((tmp)=>{return tmp.organisation === props.organization});

        let params = {
            organisation: props.organization,
            crop_id: selected_crop_id
        }
        if(index !== -1){
            const {latitude, longitude} = props.organization_list[index];
            if(latitude == null && longitude == null){
                params = {...params, ...{latitude: -3.3731, longitude: 29.9189}};
            }   
            else{
                params = {...params, ...{latitude: latitude, longitude: longitude}};
            }
        }
        else{
            params = {...params, ...{latitude: -3.3731, longitude: 29.9189}};
        }

        props.actions.seasonListRequest(params);
    }

    const getEnglishIndex = (data) => {
        let temp = Object.values(data).findIndex((tmp)=>{
            //return tmp.locale.includes(props.lang);
            return tmp.locale.includes('en_EN');
        });
        return temp;    
    }

    return (
            props.generic === false ?
                <div 
                    className="cardContainer1"
                    onClick={()=>{props.actions.particularCropListRequest({
                        crop_id: 0,
                        locale: languageContent,
                        organisation: props.organization
                    })}}
                >
                    <div className="cardImageContainer">
                        <div className="cropImage">
                        </div>
                    </div>
                    <div className="cardNameContainer1">
                        <span>Generic Crop</span>
                    </div>
                </div>
            :
                <div 
                    className="cardContainer1"
                    style={{position:'relative'}}
                    onClick={()=>{
                        "modalOpen" in props === false ?
                            props.actions.particularCropListRequest({
                                crop_id: props.data.id,
                                locale: languageContent,
                                organisation: props.organization
                            })
                        :
                            selectUnselectCrop(props.data.id)

                            "modalOpen" in props === false &&
                                seasonListRequest(props.data.id); 
                            
                            "modalOpen" in props === false &&
                                props.actions.growAreaListRequest({"organisation": props.organization})
                        
                    }}
                >
                    <div className="cardImageContainer">
                        {
                            props.data.image !== null ?
                                !props.cropImageVisibility ?
                                    <div className="cardImageContainer">
                                        <div className="cropImage"></div>
                                    </div>
                                :
                                    props.data.id in CropImages ? 
                                        <img 
                                            src={CropImages[props.data.id]} 
                                            alt="crops"
                                            className="cropImage"
                                        />
                                    :
                                        <div className="cardImageContainer">
                                            <div className="cropImage"></div>
                                        </div>
                            :
                                <div className="cropImage"></div>
                        }
                    </div>
                    
                    {
                        props.selected_crop_list !== undefined &&
                            props.selected_crop_list.includes(props.data.id) === true &&
                                <div className="idSelectedContainer">
                                    <span>{props.data.id}</span>
                                </div>
                    }

                    {
                        props.data?.crop_settings !== undefined &&
                        props.data?.crop_settings.length > 0 &&
                            props.data?.crop_settings[0].status === true ?
                                "modalOpen" in props === false &&
                                    <div 
                                        className="idSelectedContainer"
                                        style={props.user_role_type === "Superuser" ? {pointer: 'cursor'} : {}}
                                        onClick={(e)=>{
                                            if( props.user_role_type === "Superuser"){
                                                e.stopPropagation()
                                                e.preventDefault()
                                                openModal();
                                                setSelectedCrop({
                                                    "id": props.data.id,
                                                    "crop": langContent in props.data.crop_text && props.data.crop_text[langContent].displayName ? props.data.crop_text[0].displayName : null,
                                                    "status": props.data.crop_settings[0].status
                                                })
                                            }
                                        }}
                                    >
                                        <span>{props.data.id}</span>
                                    </div>
                            :
                                "modalOpen" in props === false &&
                                    <div 
                                        className="idContainer"
                                        style={props.user_role_type === "Superuser" ? {pointer: 'cursor'} : {}}
                                        onClick={(e)=>{
                                            if( props.user_role_type === "Superuser"){
                                                e.stopPropagation()
                                                e.preventDefault()
                                                openModal();
                                                setSelectedCrop({
                                                    "id": props.data.id,
                                                    "crop": langContent in props.data.crop_text && props.data.crop_text[langContent].displayName ? props.data.crop_text[0].displayName : null,
                                                    "status": props.data.crop_settings[0].status
                                                })
                                            }
                                        }}
                                    >
                                        <span>{props.data.id}</span>
                                    </div>
                    } 

                    <div className="cardNameContainer1">
                        <span>
                        {
                            props.data.crop_text.length > 0 ?
                                //langContent in props.data.crop_text &&
                                    //props.data.crop_text[langContent].displayName ? props.data.crop_text[getEnglishIndex(props.data.crop_text)].displayName : null
                                    getEnglishIndex(props.data.crop_text) !== -1 ? props.data.crop_text[getEnglishIndex(props.data.crop_text)].displayName : null
                            :
                                null
                        }
                        </span>
                    </div>

                    <ToastContainer/>
                    
                    {
                        "modalOpen" in props === false &&
                            props.user_role_type === "Superuser" &&
                                <Modal
                                    isOpen={ modalIsOpen }
                                    onRequestClose={(e)=>{
                                        e.stopPropagation();
                                        e.preventDefault();
                                        closeModal()
                                    }}
                                    style={customStyles}
                                >
                                    <h4>Do you really want to {selectedCrop.status === false ? `publish` : `unpublish`} {!selectedCrop.crop  ? "crop" :  selectedCrop.crop} ?</h4>
                                    <div className="modalBox">
                                        <div className="leftModal">
                                            <div 
                                                className="modalButton"
                                                onClick={(e)=>{
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    props.actions.cropPublishUnpublishRequest({
                                                        "crop_id":  selectedCrop.id,
                                                        "status":  !selectedCrop.status,
                                                        "organisation": props.organization
                                                    })
                                                }}
                                            >
                                                Yes
                                            </div>
                                        </div>

                                        <div className="rightModal">
                                            <div 
                                                className="modalButton" 
                                                onClick={(e)=>{
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    closeModal()
                                                }}
                                            >
                                                No
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                    }
                </div>
    )
}

const mapStateToProps = state => {
    return {
        lang: state.lang.language,
        publish_message: state.crop.publish_message,
        publish_unpublish_loading: state.crop.publish_unpublish_loading,
        user_role_type: state.auth.user_role_type,
        language_list: state.lang.language_list,
        organization: state.auth.organization,
        organization_list: state.organization.organization_list,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                particularCropListRequest,
                removeDescription,
                removeMedia,
                cropPublishUnpublishRequest,
                seasonListRequest,
                growAreaListRequest,
                cropSelectUnselectRequest
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(CropContainer);